import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { UnsupportedOperationException } from '../../../shared/data-model/models/unsupported-operation-exception.model';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { DateUtils } from '../../../shared/utils/date-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { FormConstants } from '../../constants/form.constants';
import { CrashReportStatus } from '../../data-model/enums/crash-report-status.enum';
import { CloseEventRequest } from '../../data-model/models/close-event-request.model';
import { CrashReportStatusService } from '../../services/api/crash-report-status/crash-report-status.service';
import { CrashReportDetailFormKeyService } from '../../services/form/crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailMapService } from '../../services/form/crash-report-detail-map.service';
import { ActivityActions, CrashReportActions, CrashReportApprovalActions, CrashReportStatusActions, ECrashActions } from '../actions';
import { CrashReportSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class CrashReportStatusEffects {
  crashReportApproved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportApprovalActions.crashReportApproved),
      withLatestFrom(this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, needsReviewCrashReportSummary, isReviewer]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        const newStatus = isReviewer ? CrashReportStatus.ready : CrashReportStatus.secondary_qc_check;
        // const newStatus = CrashReportStatus.ready;
        return this.crashReportStatusService.updateCrashReportDetailStatus(needsReviewCrashReportSummary.oss_id, newStatus).pipe(
          map(() => {
            if (isReviewer) {
              return ECrashActions.submitCrashReport();
            }
            return CrashReportStatusActions.crashReportStatusUpdated({ newStatus });
            // return ECrashActions.submitCrashReport();
          }),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  crashReportSubmitted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ECrashActions.crashReportSubmitted),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary)),
      switchMap(([, needsReviewCrashReportSummary]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        return this.crashReportStatusService.updateCrashReportDetailStatus(needsReviewCrashReportSummary.oss_id, CrashReportStatus.submitted).pipe(
          map(() => CrashReportStatusActions.crashReportStatusUpdated({ newStatus: CrashReportStatus.submitted })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  crashReportRejected$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportApprovalActions.crashReportRejected),
      withLatestFrom(this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, needsReviewCrashReportSummary, isReviewer]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        const newStatus = isReviewer ? CrashReportStatus.secondary_incomplete : CrashReportStatus.incomplete;
        // const newStatus = CrashReportStatus.incomplete;
        return this.crashReportStatusService.updateCrashReportDetailStatus(needsReviewCrashReportSummary.oss_id, newStatus).pipe(
          map(() => CrashReportStatusActions.crashReportStatusUpdated({ newStatus })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  updateCrashReportStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStatusActions.updateCrashReportStatus),
      switchMap(action => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        return this.crashReportStatusService.updateCrashReportDetailStatus(action.crashReportNumber, action.status).pipe(
          map(() => CrashReportStatusActions.crashReportStatusUpdated({ newStatus: action.status })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  crashReportStatusUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CrashReportStatusActions.crashReportStatusUpdated),
        withLatestFrom(
          this.store.select(CrashReportSelectors.selectCurrentPage),
          this.store.select(ServiceLocationSelectors.selectServiceLocationId),
          this.store.select(CrashReportSelectors.selectCrashReportSummary),
          this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary),
          this.store.select(CrashReportSelectors.selectIsRedirectFromNonActivity)
        ),
        tap(([action, currentPage, serviceLocationId, crashReportSummary, needsReviewCrashReportSummary, isRedirectFromNonActivity]) => {
          if (serviceLocationId === this.NEW_ORLEANS_SERVICE_LOCATION_ID) {
            let currentStatus: CrashReportStatus;
            switch (true) {
              case CommonUtils.isDefined(crashReportSummary): {
                currentStatus = crashReportSummary.status;
                break;
              }
              case CommonUtils.isDefined(needsReviewCrashReportSummary): {
                currentStatus = needsReviewCrashReportSummary.status;
                break;
              }
              default: {
                throw new TechnicalException('crash report summary and needs review crash report summary cannot both be null');
              }
            }
            if (CommonUtils.isNullOrUndefined(currentStatus)) {
              throw new TechnicalException('current status cannot be null');
            }

            if ([CrashReportStatus.open, CrashReportStatus.in_progress].includes(currentStatus) && action.newStatus === 'agent_qc_check') {
              const getValue = (schemaGroupDomId: string, subGroupDomId: string, formItemKey: string): string => {
                const key = this.keyService.getFormItemCompositeKey(schemaGroupDomId, 0, subGroupDomId, 0, formItemKey);
                return OssOptional.ofNullable(this.mapService.formItemCompositeKeyFormGroupMap.get(key))
                  .map(formGroup => formGroup.value[FormConstants.VALUE])
                  .orElseThrow(() => new TechnicalException('form control should not be null'));
              };

              const location = getValue('crash', 'location', 'CRASH_COORDINATES');
              const timeNotified = getValue('crash', 'crash', 'TIME_POLICE_NOTE');
              const dateNotified = getValue('crash', 'crash', 'DATE_POLICE_NOTE');
              const timeInvestigationComplete = getValue('hidden', 'hidden', 'TIME_INVEST_COMP');
              const dateInvestigationComplete = getValue('hidden', 'hidden', 'DATE_INVEST_COMP');

              const timeDispatched = DateUtils.convertMmSlashDdSlashYyStringAndHhMmStringToIso8601DateString(dateNotified, timeNotified);
              const timeClosed = DateUtils.convertMmSlashDdSlashYyStringAndHhMmStringToIso8601DateString(dateInvestigationComplete, timeInvestigationComplete);

              const request: CloseEventRequest = {
                service_location_id: +serviceLocationId,
                item_num: crashReportSummary.nopd_item_num,
                location,
                disposition: 'RTF',
                time_closed: timeClosed,
                notes: null,
                crash_num: crashReportSummary.oss_id,
                time_dispatched: timeDispatched,
              };
              this.store.dispatch(ActivityActions.closeItem({ request }));
            }
          }

          if (isRedirectFromNonActivity) {
            return;
          }

          let view: 'in-progress-submitted-tab-group' | 'review-rejected-approved-tab-group' | 'rejected-approved-tab-group';
          let path: '..' | '../..';

          switch (currentPage) {
            case 'inProgress':
            case 'myApproved': {
              path = '..';
              view = 'in-progress-submitted-tab-group';
              break;
            }

            case 'needsReview':
            case 'approved':
            case 'rejected': {
              path = '../..';
              view = 'review-rejected-approved-tab-group';
              break;
            }

            case 'myRejected': {
              path = '../..';
              view = 'rejected-approved-tab-group';

              /**
               * this is spaghetti code. i'm not going to implement a better solution right now
               * -- probably not ever -- cause it will almost certainly introduce new bugs
               *
               * the list container waits till the crash report, the schema, and the ruleset resolve
               * before navigating to the crash report detail container or the crash report detail review container
               *
               * nullify the crash report summary and needs review crash report summary so the list container
               * does not redirect the user to the crash report detail container or the crash report detail review container
               */
              this.store.dispatch(CrashReportActions.crashReportSummarySelected({ crashReportSummary: null }));
              this.store.dispatch(CrashReportActions.needsReviewCrashReportSummarySelected({ crashReportSummary: null }));

              break;
            }

            case 'submitted': {
              throw new TechnicalException("report status should not be updated from 'my approved' or 'my rejected' pages");
            }

            default: {
              throw new UnsupportedOperationException(`Unsupported current page: ${currentPage}`);
            }
          }

          this.router.navigate([path], { relativeTo: this.route, queryParams: { view } });
        })
      );
    },
    { dispatch: false }
  );

  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly crashReportStatusService: CrashReportStatusService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly serverErrorService: ServerErrorService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly uuidService: UuidService
  ) {}
}
