import { bootstrapApplication } from '@angular/platform-browser';
import posthog from 'posthog-js';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { environment } from './environments/environment';

if (environment.production) {
  posthog.init(environment.appConfig.posthogApiKey, { api_host: 'https://us.i.posthog.com', person_profiles: 'identified_only' });
}

bootstrapApplication(AppComponent, appConfig).catch(err => console.error(err));
