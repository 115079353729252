import { createReducer, on } from '@ngrx/store';
import { ECrashActions } from '../actions';
import { ECrashState } from '../state/e-crash.state';

export const initalECrashState: ECrashState = {
  validationObject: null,
};

export const eCrashReducer = createReducer(
  initalECrashState,
  on(ECrashActions.validationObjectLoaded, (state, action): ECrashState => ({ ...state, validationObject: action.validationObject }))
);
