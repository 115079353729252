import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, finalize, map, switchMap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { ECrashService } from '../../services/api/e-crash/e-crash.service';
import { ECrashActions } from '../actions';
import { CrashReportSelectors } from '../selectors';

@Injectable()
export class ECrashEffects {
  loadValidationObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ECrashActions.loadValidationObject),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary)
      ),
      switchMap(([, crashReportSummary, needsReviewCrashReportSummary]) => {
        let crashNumber: string;
        switch (true) {
          case CommonUtils.isDefined(crashReportSummary): {
            crashNumber = crashReportSummary.oss_id;
            break;
          }
          case CommonUtils.isDefined(needsReviewCrashReportSummary): {
            crashNumber = needsReviewCrashReportSummary.oss_id;
            break;
          }
          default: {
            throw new TechnicalException('crash report summary and needs review crash report summary cannot both be null');
          }
        }
        if (CommonUtils.isNullOrUndefined(crashNumber)) {
          throw new TechnicalException('crash number cannot be null');
        }
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Validating eCrash report...', loaderId }));
        return this.eCrashService.validateReport(crashNumber).pipe(
          map(validationObject => ECrashActions.validationObjectLoaded({ validationObject })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  submitCrashReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ECrashActions.submitCrashReport),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary)),
      switchMap(([, needsReviewCrashReportSummary]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Submitting eCrash report...', loaderId }));
        return this.eCrashService.submitReport(needsReviewCrashReportSummary.oss_id).pipe(
          map(() => ECrashActions.crashReportSubmitted()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly serverErrorService: ServerErrorService,
    private readonly eCrashService: ECrashService,
    private readonly uuidService: UuidService
  ) {}
}
