import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { UnsupportedOperationException } from '../../../shared/data-model/models/unsupported-operation-exception.model';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CrashReportStatus } from '../../data-model/enums/crash-report-status.enum';
import { CrashReportStatusService } from '../../services/api/crash-report-status/crash-report-status.service';
import { CrashReportActions, CrashReportStatusActions, ECrashActions, NavigationActions } from '../actions';
import { CrashReportSelectors, NavigationSelectors } from '../selectors';

@Injectable()
export class CrashReportStatusEffects {
  crashReportSubmitted$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ECrashActions.crashReportSubmitted),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary)),
      switchMap(([, needsReviewCrashReportSummary]) => {
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        return this.crashReportStatusService.updateCrashReportDetailStatus(needsReviewCrashReportSummary.oss_id, CrashReportStatus.submitted, false).pipe(
          map(() => CrashReportStatusActions.crashReportStatusUpdated({ newStatus: CrashReportStatus.submitted })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  updateCrashReportStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStatusActions.updateCrashReportStatus),
      concatLatestFrom(() => this.store.select(NavigationSelectors.selectIsRedirectFromNonActivity)),
      switchMap(([action, isRedirectFromNonActivity]) => {
        const loaderId = this.uuidService.generate();
        const shouldReconcile = isRedirectFromNonActivity;
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report status...', loaderId }));
        return this.crashReportStatusService.updateCrashReportDetailStatus(action.crashReportNumber, action.status, shouldReconcile).pipe(
          tap(response => {
            if (shouldReconcile) {
              if (response.verification_was_successful) {
                this.snackbarService.showSnackbar('The item number has been successfully verified', 'success');
              } else {
                this.snackbarService.showSnackbar('Unable to verify the item number', 'error');
              }
            }
          }),
          map(() => CrashReportStatusActions.crashReportStatusUpdated({ newStatus: action.status })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  crashReportStatusUpdated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CrashReportStatusActions.crashReportStatusUpdated),
        tap(() => {
          this.store.dispatch(CrashReportActions.setCrashReportSummary({ crashReportSummary: null }));
          this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
        }),
        withLatestFrom(this.store.select(CrashReportSelectors.selectCurrentTab), this.store.select(NavigationSelectors.selectIsRedirectFromNonActivity)),
        filter(([action, , isRedirectFromNonActivity]) => {
          switch (true) {
            case action.newStatus === 'submitted':
              this.store.dispatch(NavigationActions.navigateFromReviewFormToReviewRejectedApprovedList());
              return false;

            case action.newStatus === 'agent_qc_check' && isRedirectFromNonActivity:
              this.store.dispatch(NavigationActions.navigateFromNonReviewFormToMissingActivityTable());
              return false;

            case action.newStatus === 'agent_qc_check' && !isRedirectFromNonActivity:
              this.store.dispatch(NavigationActions.navigateFromNonReviewFormToInProgressMyApprovedList());
              return false;

            default:
              return true;
          }
        }),
        tap(([, currentPage]) => {
          console.warn(`CrashReportStatusEffects::crashReportStatusUpdated$ reached in error. currentPage: ${currentPage}`);

          let view: 'in-progress-submitted-tab-group' | 'review-rejected-approved-tab-group' | 'rejected-approved-tab-group';
          let path: '..' | '../..';

          switch (currentPage) {
            case 'inProgress':
            case 'myApproved':
              path = '..';
              view = 'in-progress-submitted-tab-group';
              break;

            case 'needsReview':
            case 'approved':
            case 'rejected':
              path = '../..';
              view = 'review-rejected-approved-tab-group';
              break;

            case 'myRejected':
              path = '../..';
              view = 'rejected-approved-tab-group';
              break;

            case 'submitted':
              throw new UnsupportedOperationException("report status should not be updated from 'submitted' page");

            default:
              throw new UnsupportedOperationException(`Unsupported current page: ${currentPage}`);
          }

          this.router.navigate([path], { relativeTo: this.route, queryParams: { view } });
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly crashReportStatusService: CrashReportStatusService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly serverErrorService: ServerErrorService,
    private readonly uuidService: UuidService,
    private readonly snackbarService: SnackbarService
  ) {}
}
