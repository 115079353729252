import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { Schema } from '../../../data-model/types/schema.type';

@Injectable({ providedIn: 'root' })
export class SchemaService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  getSchema(version: number | null, serviceLocationId: number): Observable<{ schema: Schema; version: number }> {
    const params = { service_location_id: serviceLocationId };
    if (CommonUtils.isDefined(version)) {
      params['version'] = version;
    }
    return this.apiGateway
      .get<{ schema: unknown; version: number }>('oss/crashReport/schema', params, true, {}, null, '2.0.0')
      .pipe(map(this.schemaResponseMapper));
  }

  // the back end uses subgroups and formitems, but the front end uses subGroups and formItems
  // use regex to replace the keys and return the updated schema
  private schemaResponseMapper = (schemaResponse: { schema: unknown; version: number }): { schema: Schema; version: number } => {
    const schemaString = JSON.stringify(schemaResponse.schema);
    const updatedSchemaString = schemaString.replace(/subgroups/g, 'subGroups').replace(/formitems/g, 'formItems');
    const updatedSchema = JSON.parse(updatedSchemaString);
    return { schema: updatedSchema, version: schemaResponse.version };
  };
}
