<!-- currently showing nav bar so user can navigate from crash report detail to crash report summary list -->
<!-- @if (true) {
  <app-main-navigation-bar></app-main-navigation-bar>
} -->
<router-outlet></router-outlet>
@if (isLoading$ | async) {
  <div class="overlay">
    <mat-progress-spinner mode="indeterminate"></mat-progress-spinner>
    <div class="loading-text">{{ loadingMessage$ | async }}</div>
  </div>
}
