import { ApplicationConfig, isDevMode } from '@angular/core';
import { provideRouter } from '@angular/router';

import { provideHttpClient } from '@angular/common/http';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideEffects } from '@ngrx/effects';
import { provideStore } from '@ngrx/store';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { routes } from './app.routes';
import { AuthEffects } from './modules/auth/store/effects/auth.effects';
import { ActivityEffects } from './modules/crash-report/store/effects/activity.effects';
import { CrashReportApprovalEffects } from './modules/crash-report/store/effects/crash-report-approval.effects';
import { CrashReportStatusEffects } from './modules/crash-report/store/effects/crash-report-status.effects';
import { CrashReportStructuralEffects } from './modules/crash-report/store/effects/crash-report-structural.effects';
import { CrashReportEffects } from './modules/crash-report/store/effects/crash-report.effects';
import { ECrashEffects } from './modules/crash-report/store/effects/e-crash.effects';
import { RuleSetEffects } from './modules/crash-report/store/effects/rule-set.effects';
import { SchemaEffects } from './modules/crash-report/store/effects/schema.effects';
import { ServiceLocationEffects } from './modules/crash-report/store/effects/service-locations.effects';
import { UnitEffects } from './modules/crash-report/store/effects/unit.effects';
import { SnackbarEffects } from './modules/shared/modules/snackbar/store/effects/snackbar.effects';
import { metaReducers } from './store/meta-reducers/init-state-from-local-storage.meta-reducer';
import { reducers } from './store/state/app.state';

const effects = [
  CrashReportEffects,
  AuthEffects,
  SnackbarEffects,
  UnitEffects,
  ActivityEffects,
  ServiceLocationEffects,
  SchemaEffects,
  RuleSetEffects,
  ECrashEffects,
  CrashReportApprovalEffects,
  CrashReportStatusEffects,
  CrashReportStructuralEffects,
];

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes),
    provideAnimationsAsync(),
    provideStore(reducers, { metaReducers }),
    provideEffects(effects),
    provideStoreDevtools({ maxAge: 25, logOnly: !isDevMode() }),
    provideHttpClient(),
  ],
};
