import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { OssUnit } from '../../../data-model/models/oss-unit.model';

@Injectable({ providedIn: 'root' })
export class UnitService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  getUnits(serviceLocationId: number): Observable<OssUnit[]> {
    return this.apiGateway.get<OssUnit[]>('oss/agent/units', { service_location_id: serviceLocationId }, true, null, null, '1.0.0');
  }

  assignUnit(unitId: number): Observable<{ id: number }> {
    return this.apiGateway.post<{ id: number }>('oss/agent/unitAssignment', { unit_id: unitId }, true, null, '1.0.0');
  }

  unassignUnit(assignmentId: number): Observable<{ success: boolean }> {
    return this.apiGateway.post<{ success: boolean }>('oss/agent/endAssignment', { assignment_id: assignmentId }, true, null, '1.0.0');
  }
}
