import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Action } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { Observable, of } from 'rxjs';
import { SnackbarActions } from '../../modules/snackbar/store/actions';
import { LocalStorageService } from '../local-storage/local-storage.service';

@Injectable({ providedIn: 'root' })
export class ServerErrorService {
  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router
  ) {}

  handleError(error: HttpErrorResponse): Observable<Action> {
    if (error.status === 401 || error.status === 403) {
      this.localStorageService.clearApiToken();
      this.localStorageService.clearUser();
      this.localStorageService.clearServiceLocation();
      this.localStorageService.clearUnit();
      this.localStorageService.clearAssignmentId();

      this.router.navigate(['/auth/sign-in']);
      return of(SnackbarActions.showSnackbar({ message: 'Unauthorized. Please sign in again.', level: 'error' }));
    }
    if (error.status === 503) {
      return of(SnackbarActions.showSnackbar({ message: 'App is currently offline. Unable to complete request.', level: 'info' }));
    }
    if (error.status === 400) {
      if (error.error['message'] === 'duplicate item num') {
        return of(SnackbarActions.showSnackbar({ message: 'Item number is already in use.', level: 'error' }));
      }
      return of(SnackbarActions.showSnackbar({ message: error.error['display_message'], level: 'error' }));
    }
    Sentry.captureException(error);
    return of(SnackbarActions.showSnackbar({ message: 'Unspecified server error.', level: 'error' }));
  }
}
