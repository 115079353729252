import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs';
import { NavigationActions } from '../actions';

@Injectable()
export class NavigationEffects {
  navigateFromReviewFormToNeedsReviewApprovedRejectedList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigateFromReviewFormToReviewRejectedApprovedList),
        tap(() => this.router.navigate(['../..'], { relativeTo: this.route, queryParams: { view: 'review-rejected-approved-tab-group' } }))
      );
    },
    { dispatch: false }
  );

  navigateFromNonReviewFormToInProgressMyApprovedList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigateFromNonReviewFormToInProgressMyApprovedList),
        tap(() => this.router.navigate(['..'], { relativeTo: this.route, queryParams: { view: 'in-progress-submitted-tab-group' } }))
      );
    },
    { dispatch: false }
  );

  navigateFromNonReviewFormToMissingActivityTable$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigateFromNonReviewFormToMissingActivityTable),
        tap(() => this.router.navigate(['/crash-report/activity']))
      );
    },
    { dispatch: false }
  );

  navigateFromCloseEventToInProgressMyApprovedList$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(NavigationActions.navigateFromCloseEventToInProgressMyApprovedList),
        tap(() => this.router.navigate(['..'], { relativeTo: this.route, queryParams: { view: 'in-progress-submitted-tab-group' } }))
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly route: ActivatedRoute
  ) {}
}
