import { Routes } from '@angular/router';
import { AuthGuard } from './modules/auth/guards/auth/auth.guard';

export const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'crash-report',
  },
  {
    path: 'auth',
    loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule),
  },
  {
    path: 'crash-report',
    loadChildren: () => import('./modules/crash-report/crash-report.module').then(m => m.CrashReportModule),
    canActivate: [AuthGuard],
  },
  {
    path: '**',
    redirectTo: 'crash-report',
  },
];
