import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ClientVersion } from '../../../../auth/data-model/models/client-version.model';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';

@Injectable({ providedIn: 'root' })
export class VersionService {
  constructor(private readonly apiGatewayService: ApiGatewayService) {}

  getCurrentVersion(): Observable<ApiOperationStatus & { clientVersion: ClientVersion }> {
    return this.apiGatewayService.get('oss/clientversion/index', null, true, null, null, '1.0.0');
  }
}
