import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { AddCrashReportDetailRequest } from '../../../data-model/models/add-crash-report-detail-request.model';
import { AddOrRemoveGroupRequest } from '../../../data-model/models/add-or-remove-group-request.model';
import { CrashReportDetailUpdateRequest } from '../../../data-model/models/crash-report-detail-update-request.model';
import { CrashReportDetail } from '../../../data-model/models/crash-report-detail.model';
import { CrashReportSummary } from '../../../data-model/models/crash-report-summary.model';
import { FormItemMetaData } from '../../../data-model/models/form-item-meta-data.model';
import { SchemaGroupMetaData } from '../../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../../data-model/models/sub-group-meta-data.model';
import { CrashReportDetailStatus } from '../../../data-model/types/crash-report-detail-status.type';

@Injectable({ providedIn: 'root' })
export class CrashReportService {
  private readonly CRASH_NUMBER = 'Crash_Number';
  private readonly SCHEMA_GROUP_DOM_ID = 'Schema_Group_Dom_Id';
  private readonly SCHEMA_GROUP_INDEX = 'Schema_Group_Index';
  private readonly SUB_GROUP_DOM_ID = 'Sub_Group_Dom_Id';
  private readonly SUB_GROUP_INDEX = 'Sub_Group_Index';
  private readonly SERVICE_LOCATION_ID = 'Service_Location_Id';
  private readonly LOCAL_REPORT_NUMBER = 'Local_Report_Number';
  private readonly SCHEMA_GROUP_API_INDEX = 'Schema_Group_Api_Index';
  private readonly SUB_GROUP_API_INDEX = 'Sub_Group_Api_Index';
  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;
  private readonly BALTIMORE_SERVICE_LOCATION_ID = 2;

  constructor(private readonly apiGateway: ApiGatewayService) {}

  getInProgressCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<CrashReportSummary[]> {
    return this.getCrashReportSummaries(serviceLocationId, ['open', 'in_progress'], limit, offset);
  }

  getSubmittedCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<CrashReportSummary[]> {
    let statuses: CrashReportDetailStatus[];
    switch (serviceLocationId) {
      case this.NEW_ORLEANS_SERVICE_LOCATION_ID: {
        statuses = ['agent_qc_check', 'secondary_qc_check'];
        break;
      }
      case this.BALTIMORE_SERVICE_LOCATION_ID: {
        statuses = ['submitted'];
        break;
      }
      default: {
        throw new Error(`Unsupported service location id: ${serviceLocationId}`);
      }
    }
    return this.getCrashReportSummaries(serviceLocationId, statuses, limit, offset);
  }

  getMyApprovedCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<CrashReportSummary[]> {
    return this.getCrashReportSummaries(serviceLocationId, ['submitted'], limit, offset);
  }

  getMyRejectedCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<CrashReportSummary[]> {
    return this.getCrashReportSummaries(serviceLocationId, ['incomplete', 'secondary_incomplete'], limit, offset);
  }

  getCrashReportDetail(crash_num: string): Observable<CrashReportDetail> {
    return this.apiGateway.get('oss/crashReport/report', { crash_num });
  }

  updateCrashReportDetail(request: CrashReportDetailUpdateRequest, formItemMetaData: FormItemMetaData): Observable<ApiOperationStatus> {
    return this.apiGateway.post(
      'oss/crashReport/formField',
      request,
      true,
      this.getHeadersOnCrashNumberAndFormItemMetaData(request.crash_num, formItemMetaData),
      '2.0.0'
    );
  }

  addSchemaGroup(
    request: AddOrRemoveGroupRequest,
    schemaGroupMetaData: SchemaGroupMetaData,
    newSchemaGroupIndex: number,
    newSchemaGroupApiIndex: number
  ): Observable<CrashReportDetail> {
    return this.apiGateway.post(
      'oss/crashReport/addToList',
      request,
      true,
      this.getHeadersOnCrashNumberAndSchemaGroupMetaData(request.crash_num, schemaGroupMetaData, newSchemaGroupIndex, newSchemaGroupApiIndex)
    );
  }

  removeSchemaGroup(request: AddOrRemoveGroupRequest, schemaGroupMetaData: SchemaGroupMetaData): Observable<CrashReportDetail> {
    return this.apiGateway.delete(
      'oss/crashReport/removeFromList',
      request,
      true,
      this.getHeadersOnCrashNumberAndSchemaGroupMetaData(request.crash_num, schemaGroupMetaData, schemaGroupMetaData.index, schemaGroupMetaData.apiIndex)
    );
  }

  addSubGroup(
    request: AddOrRemoveGroupRequest,
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    newSubGroupIndex: number,
    newSubGroupApiIndex: number
  ): Observable<CrashReportDetail> {
    return this.apiGateway.post(
      'oss/crashReport/addToList',
      request,
      true,
      this.getHeadersOnCrashNumberAndSchemaGroupMetaDataAndSubGroupMetaData(
        request.crash_num,
        schemaGroupMetaData,
        subGroupMetaData,
        newSubGroupIndex,
        newSubGroupApiIndex
      )
    );
  }

  removeSubGroup(
    request: AddOrRemoveGroupRequest,
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData
  ): Observable<CrashReportDetail> {
    return this.apiGateway.delete(
      'oss/crashReport/removeFromList',
      request,
      true,
      this.getHeadersOnCrashNumberAndSchemaGroupMetaDataAndSubGroupMetaData(
        request.crash_num,
        schemaGroupMetaData,
        subGroupMetaData,
        subGroupMetaData.index,
        subGroupMetaData.apiIndex
      )
    );
  }

  addCrashReportDetail(request: AddCrashReportDetailRequest): Observable<ApiOperationStatus> {
    const additionalHeaders = { [this.SERVICE_LOCATION_ID]: request.service_location_id.toString(), [this.LOCAL_REPORT_NUMBER]: request.local_rpt_num };
    return this.apiGateway.post('oss/crashReport/newReport', request, true, additionalHeaders, '2.0.0');
  }

  swapVehicles(crashNumber: string, vehicleIndex1: number, vehicleIndex2: number): Observable<CrashReportDetail> {
    const body = { crash_num: crashNumber, group_type: 'vehicle', group_index_1: vehicleIndex1 + 1, group_index_2: vehicleIndex2 + 1 };
    return this.apiGateway.put('oss/crashReport/swapGroup', body, true, {}, '2.0.0');
  }

  private getHeadersOnCrashNumberAndFormItemMetaData(crashNumber: string, formItemMetaData: FormItemMetaData): { [key: string]: string } {
    return {
      [this.CRASH_NUMBER]: crashNumber,
      [this.SCHEMA_GROUP_DOM_ID]: formItemMetaData.schemaGroupDomId.toString(),
      [this.SCHEMA_GROUP_INDEX]: formItemMetaData.schemaGroupIndex.toString(),
      [this.SUB_GROUP_DOM_ID]: formItemMetaData.subGroupDomId.toString(),
      [this.SUB_GROUP_INDEX]: formItemMetaData.subGroupIndex.toString(),
      [this.SCHEMA_GROUP_API_INDEX]: formItemMetaData.schemaGroupApiIndex.toString(),
      [this.SUB_GROUP_API_INDEX]: formItemMetaData.subGroupApiIndex.toString(),
    };
  }

  private getHeadersOnCrashNumberAndSchemaGroupMetaData(
    crashNumber: string,
    schemaGroupMetaData: SchemaGroupMetaData,
    schemaGroupIndex: number,
    schemaGroupApiIndex: number
  ): { [key: string]: string } {
    return {
      [this.CRASH_NUMBER]: crashNumber,
      [this.SCHEMA_GROUP_DOM_ID]: schemaGroupMetaData.domId.toString(),
      [this.SCHEMA_GROUP_INDEX]: schemaGroupIndex.toString(),
      [this.SUB_GROUP_DOM_ID]: StringUtils.EMPTY,
      [this.SUB_GROUP_INDEX]: StringUtils.EMPTY,
      [this.SCHEMA_GROUP_API_INDEX]: schemaGroupApiIndex.toString(),
      [this.SUB_GROUP_API_INDEX]: StringUtils.EMPTY,
    };
  }

  private getHeadersOnCrashNumberAndSchemaGroupMetaDataAndSubGroupMetaData(
    crashNumber: string,
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData,
    subGroupIndex: number,
    subGroupApiIndex: number
  ): { [key: string]: string } {
    return {
      [this.CRASH_NUMBER]: crashNumber,
      [this.SCHEMA_GROUP_DOM_ID]: schemaGroupMetaData.domId.toString(),
      [this.SCHEMA_GROUP_INDEX]: schemaGroupMetaData.index.toString(),
      [this.SUB_GROUP_DOM_ID]: subGroupMetaData.domId.toString(),
      [this.SUB_GROUP_INDEX]: subGroupIndex.toString(),
      [this.SCHEMA_GROUP_API_INDEX]: schemaGroupMetaData.apiIndex.toString(),
      [this.SUB_GROUP_API_INDEX]: subGroupApiIndex.toString(),
    };
  }

  private getCrashReportSummaries(
    serviceLocationId: number,
    statuses: CrashReportDetailStatus[],
    limit: number = 1000,
    offset: number = 0
  ): Observable<CrashReportSummary[]> {
    if (CommonUtils.isEmpty(statuses)) {
      throw new TechnicalException('must provide statuses to get crash report summaries');
    }
    const additionalHeaders = { ETag: StringUtils.EMPTY };
    return this.apiGateway.get(
      'oss/dashboard/reportsByStatus',
      null,
      true,
      additionalHeaders,
      this.buildUrlSearchParams(serviceLocationId, limit, offset, statuses),
      '2.0.0'
    );
  }

  private buildUrlSearchParams(serviceLocationId: number, limit: number, offset: number, statuses: string[]): URLSearchParams {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('service_location_id', serviceLocationId.toString());
    urlSearchParams.append('limit', limit.toString());
    urlSearchParams.append('offset', offset.toString());
    statuses.forEach(status => urlSearchParams.append('status', status));
    return urlSearchParams;
  }
}
