import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CollectionUtils } from '../../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { RuleSet } from '../../../data-model/types/rule-set.type';

@Injectable({ providedIn: 'root' })
export class RuleSetService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  getRuleSet(version: number | null, serviceLocationId: number): Observable<{ ruleSet: RuleSet; version: number }> {
    const params = { service_location_id: serviceLocationId };

    if (CommonUtils.isDefined(version)) {
      params['version'] = version;
    }

    return this.apiGateway.get<{ rule_set: RuleSet; version: number }[]>('oss/crashReport/ruleSet', params, true, null, null, '2.0.0').pipe(
      // the back end returns a list of rule sets, i believe this is a mistake
      // validate that there is only one rule set and return it
      // the back end returns an object with a rule_set key, but the front end expects a ruleSet key
      // return the rule set with the correct key
      map(ruleSetResponse => {
        if (ruleSetResponse.length === 0) {
          console.error(`no rule set found for service location id ${serviceLocationId} and version ${version}`);
          throw new TechnicalException('No rule set found');
        }
        if (ruleSetResponse.length > 1) {
          console.error(`multiple rule sets found for service location id ${serviceLocationId} and version ${version}`);
          throw new TechnicalException('Multiple rule sets found');
        }
        const onlyRuleSet = CollectionUtils.getOnlyElement(ruleSetResponse);
        return { ruleSet: onlyRuleSet.rule_set, version: onlyRuleSet.version };
      })
    );
  }
}
