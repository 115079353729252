/* eslint-disable @ngrx/no-dispatch-in-effects */
/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, EMPTY, filter, finalize, forkJoin, map, mergeMap, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { NetworkStatusService } from '../../../shared/services/network-status/network-status.service';
import { PosthogService } from '../../../shared/services/posthog/posthog.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { ObjectUtils } from '../../../shared/utils/object-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { OssStream } from '../../../shared/utils/oss-stream';
import { StringUtils } from '../../../shared/utils/string-utils';
import { FormConstants } from '../../constants/form.constants';
import { FormItemType } from '../../data-model/enums/form-item-type.enum';
import { AddCrashReportDetailRequest } from '../../data-model/models/add-crash-report-detail-request.model';
import { CrashReportDetailUpdateRequest } from '../../data-model/models/crash-report-detail-update-request.model';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { FormItemMetaData } from '../../data-model/models/form-item-meta-data.model';
import { SupervisorCrashReportSummary } from '../../data-model/models/supevisor-crash-report-summary.model';
import { CrashReportService } from '../../services/api/crash-report/crash-report.service';
import { ReviewerService } from '../../services/api/reviewer/reviewer.service';
import { RuleSetService } from '../../services/api/rule-set/rule-set.service';
import { SchemaService } from '../../services/api/schema/schema.service';
import { SupervisorService } from '../../services/api/supervisor/supervisor.service';
import { CrashReportDetailFormKeyService } from '../../services/form/crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormService } from '../../services/form/crash-report-detail-form/crash-report-detail-form.service';
import { CrashReportDetailMapService } from '../../services/form/crash-report-detail-map.service';
import { CrashReportActions, RuleSetActions, SchemaActions } from '../actions';
import { CrashReportSelectors, RuleSetSelectors, SchemaSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class CrashReportEffects {
  // crash report summary loading effects
  loadInProgressCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadInProgressCrashReportSummaries),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocation)),
      switchMap(([, serviceLocation]) =>
        this.crashReportService.getInProgressCrashReportSummaries(serviceLocation.id).pipe(
          map(inProgressCrashReportSummaries => CrashReportActions.inProgressCrashReportSummariesLoaded({ inProgressCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  loadSubmittedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadSubmittedCrashReportSummaries),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocation),
        this.store.select(CrashReportSelectors.selectNumberOfSubmittedCrashReportSummaries)
      ),
      switchMap(([action, serviceLocation, numberOfSubmittedCrashReportSummaries]) => {
        if (numberOfSubmittedCrashReportSummaries === -1) {
          return this.crashReportService.getSubmittedCrashReportSummaries(serviceLocation.id).pipe(
            concatMap(allSubmittedCrashReportSummaries => {
              const numberOfSubmittedCrashReportSummaries = allSubmittedCrashReportSummaries.length;
              const currentSubmittedCrashReportSummaries = allSubmittedCrashReportSummaries.slice(action.offset, action.offset + action.limit);
              return [
                CrashReportActions.setNumberOfSubmittedCrashReportSummaries({ numberOfSubmittedCrashReportSummaries }),
                CrashReportActions.submittedCrashReportSummariesLoaded({ submittedCrashReportSummaries: currentSubmittedCrashReportSummaries }),
              ];
            }),
            catchError(error => this.serverErrorService.handleError(error))
          );
        }
        return this.crashReportService.getSubmittedCrashReportSummaries(serviceLocation.id, action.limit, action.offset).pipe(
          map(submittedCrashReportSummaries => CrashReportActions.submittedCrashReportSummariesLoaded({ submittedCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadMyRejectedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadMyRejectedCrashReportSummaries),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([, serviceLocationId]) => {
        return this.crashReportService.getMyRejectedCrashReportSummaries(serviceLocationId).pipe(
          map(myRejectedCrashReportSummaries => CrashReportActions.myRejectedCrashReportSummariesLoaded({ myRejectedCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadMyApprovedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadMyApprovedCrashReportSummaries),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([, serviceLocationId]) => {
        return this.crashReportService.getMyApprovedCrashReportSummaries(serviceLocationId).pipe(
          map(myApprovedCrashReportSummaries => CrashReportActions.myApprovedCrashReportSummariesLoaded({ myApprovedCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadNeedsReviewCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadNeedsReviewCrashReportSummaries),
      withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocationId), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, serviceLocationId, isReviewer]) => {
        const supervisorCrashReportSummaries$ = isReviewer
          ? this.reviewerService.getNeedsReviewCrashReportSummaries(serviceLocationId)
          : this.supervisorService.getNeedsReviewCrashReportSummaries(serviceLocationId);
        return supervisorCrashReportSummaries$.pipe(
          map(needsReviewCrashReportSummaries => CrashReportActions.needsReviewCrashReportSummariesLoaded({ needsReviewCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadApprovedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadApprovedCrashReportSummaries),
      withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocationId), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, serviceLocationId, isReviewer]) => {
        const supervisorCrashReportSummaries$ = isReviewer
          ? this.reviewerService.getApprovedCrashReportSummaries(serviceLocationId)
          : this.supervisorService.getApprovedCrashReportSummaries(serviceLocationId);
        return supervisorCrashReportSummaries$.pipe(
          map(approvedCrashReportSummaries => CrashReportActions.approvedCrashReportSummariesLoaded({ approvedCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadRejectedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadRejectedCrashReportSummaries),
      withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocationId), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, serviceLocationId, isReviewer]) => {
        const supervisorCrashReportSummaries$ = isReviewer
          ? this.reviewerService.getRejectedCrashReportSummaries(serviceLocationId)
          : this.supervisorService.getRejectedCrashReportSummaries(serviceLocationId);
        return supervisorCrashReportSummaries$.pipe(
          map(rejectedCrashReportSummaries => CrashReportActions.rejectedCrashReportSummariesLoaded({ rejectedCrashReportSummaries })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  // TODO: add error handling
  // crash report detail effects -- add report, update report, add/remove schema group/sub group, etc.
  loadCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadCrashReportDetail),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary),
        this.store.select(SchemaSelectors.selectDefaultSchema),
        this.store.select(SchemaSelectors.selectDefaultSchemaVersion),
        this.store.select(RuleSetSelectors.selectDefaultRuleSet),
        this.store.select(RuleSetSelectors.selectDefaultRuleSetVersion),
        this.store.select(ServiceLocationSelectors.selectServiceLocation)
      ),
      tap(([, crashReportSummary, needsReviewCrashReportSummary]) => {
        if (CommonUtils.isNullOrUndefined(crashReportSummary) && CommonUtils.isNullOrUndefined(needsReviewCrashReportSummary)) {
          this.router.navigateByUrl('/crash-report');
          crashReportSummary;
        }
      }),
      filter(
        ([, crashReportSummary, needsReviewCrashReportSummary]) =>
          CommonUtils.isDefined(crashReportSummary) || CommonUtils.isDefined(needsReviewCrashReportSummary)
      ),
      switchMap(
        ([
          ,
          crashReportSummary,
          needsReviewCrashReportSummary,
          defaultSchema,
          defaultSchemaVersion,
          defaultRuleSet,
          defaultRuleSetVersion,
          serviceLocation,
        ]) => {
          let crashReportId;
          let schemaVersion;
          let ruleSetVersion;

          switch (true) {
            case CommonUtils.isDefined(crashReportSummary) && CommonUtils.isNullOrUndefined(needsReviewCrashReportSummary): {
              crashReportId = crashReportSummary.oss_id;
              schemaVersion = crashReportSummary.schema_version;
              ruleSetVersion = crashReportSummary.rule_set_version;
              break;
            }

            case CommonUtils.isNullOrUndefined(crashReportSummary) && CommonUtils.isDefined(needsReviewCrashReportSummary): {
              crashReportId = needsReviewCrashReportSummary.oss_id;
              schemaVersion = needsReviewCrashReportSummary.schema_version;
              ruleSetVersion = needsReviewCrashReportSummary.rule_set_version;
              break;
            }

            default: {
              throw new TechnicalException('crash report summary and needs review crash report summary cannot both be defined');
            }
          }

          if (this.networkStatusSerivce.isOffline) {
            return of(
              CrashReportActions.crashReportDetailLoaded({ crashReportDetail: {} }),
              SchemaActions.schemaLoaded({ schema: [...defaultSchema], version: defaultSchemaVersion }),
              RuleSetActions.ruleSetLoaded({ ruleSet: [...defaultRuleSet], version: defaultRuleSetVersion })
            );
          }

          return forkJoin({
            crashReportDetail: this.crashReportService.getCrashReportDetail(crashReportId),
            schemaResponse: this.schemaService.getSchema(schemaVersion, serviceLocation.id),
            ruleSetResponse: this.ruleSetService.getRuleSet(ruleSetVersion, serviceLocation.id),
          }).pipe(
            mergeMap(({ crashReportDetail, schemaResponse, ruleSetResponse }) => {
              return of(
                CrashReportActions.crashReportDetailLoaded({ crashReportDetail }),
                SchemaActions.schemaLoaded(schemaResponse),
                RuleSetActions.ruleSetLoaded(ruleSetResponse)
              );
            })
          );
        }
      )
    );
  });

  updateCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.updateCrashReportDetail),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary)
      ),
      concatMap(([action, crashReportSummary, needsReviewCrashReportSummary]) => {
        return this.crashReportService
          .updateCrashReportDetail(this.buildCrashReportDetailUpdateRequest(crashReportSummary, needsReviewCrashReportSummary, action), action.metaData)
          .pipe(
            // TODO: was updating the form control so the in sync with database icon would display correctly
            //    removed when i added sections and pages cause it was preventing error from being displayed correctly
            //    or user from navigating to the next page
            tap(() => this.formService.updateIsDefaultValueOnFormItemMetaData(action.metaData)),
            tap(() => this.formService.updateFormItemOnFormItemMetaDataAndValue(action.metaData, action.value, action.isSetInBackground)),
            map(() => CrashReportActions.crashReportDetailUpdated()),
            catchError((error: HttpErrorResponse) => {
              // TODO: dowe need the following line?
              // this.formService.updateFormItemMetaDataOnValueUpdateResponse(action.metaData, false);
              if (this.isDatabaseError(error)) {
                this.snackbarService.showSnackbar('The value you have provided is invalid. Please try again.', 'error');
                return EMPTY;
              }
              return this.serverErrorService.handleError(error);
            })
          );
      })
    );
  });

  addCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.addCrashReportDetail),
      withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocation), this.store.select(CrashReportSelectors.selectIsRedirectFromActivity)),
      switchMap(([action, serviceLocation, isRedirectFromActvity]) => {
        const request: AddCrashReportDetailRequest = {
          local_rpt_num: action.newCrashReportFormValue.itemNumber,
          service_location_id: serviceLocation.id,
          state_inner_rpt_num: null,
        };
        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Adding crash report...' }));
        return this.crashReportService.addCrashReportDetail(request).pipe(
          switchMap(response => {
            if (this.responseIsFromServiceWorker(response)) {
              // TODO: i don't think the null properties matter, this is a dummy object that
              // will be replaced by the real crash report summary when the app goes online
              const crashReportSummary: CrashReportSummary = {
                agent: null,
                date: null,
                last_update: null,
                nopd_item_num: action.newCrashReportFormValue.itemNumber,
                oss_id: response['temporaryCrashNumber'],
                schema_version: null,
                status: null,
                rule_set_version: null,
              };
              return [
                CrashReportActions.setNewCrashReportFormValue({
                  // report num 2 is set on the crash report detail by the server
                  // if the app is offline, we need to manually set it so the user does not see an empty form control
                  newCrashReportFormValue: { ...action.newCrashReportFormValue, reportNum2: crashReportSummary.nopd_item_num },
                }),
                CrashReportActions.crashReportSummarySelected({ crashReportSummary }),
                LoadingActions.hideLoadingIndicator(),
              ];
            }
            return this.crashReportService.getInProgressCrashReportSummaries(serviceLocation.id).pipe(
              mergeMap(crashReportSummaries => {
                const crashReportSummary = OssStream.from(crashReportSummaries)
                  .filter(
                    summary => summary[this.SERVICE_LOCATION_ID_ITEM_NUMBER_PROPERTY_MAP.get(serviceLocation.id)] === action.newCrashReportFormValue.itemNumber
                  )
                  .findFirst()
                  .orElseThrow(() => new TechnicalException('Crash report summary not found'));
                this.posthogService.captureItemCreatedEvent(crashReportSummary.oss_id, crashReportSummary.agent, serviceLocation.id);
                return [
                  CrashReportActions.setNewCrashReportFormValue({ newCrashReportFormValue: action.newCrashReportFormValue }),
                  CrashReportActions.crashReportSummarySelected({ crashReportSummary }),
                  LoadingActions.hideLoadingIndicator(),
                ];
              }),
              catchError(error => this.serverErrorService.handleError(error))
            );
          }),
          catchError(error => {
            if (
              (OssOptional.ofNullable(error.error.err_code).orElseNull() === 'crv2-0010' ||
                OssOptional.ofNullable(error.error.message).orElseNull() === 'duplicate item num') &&
              isRedirectFromActvity
            ) {
              const errorObject = OssOptional.ofNullable(error.error).orElseThrow(() => new TechnicalException('error object does not exist'));
              const results = OssOptional.ofNullable(errorObject.results).orElseThrow(() => new TechnicalException('results does not exist on error object'));
              const crashNumber = OssOptional.ofNullable(results.crash_num).orElseThrow(
                () => new TechnicalException('crash_num does not exist on error.results object')
              );
              this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Getting crash report...' }));
              return this.crashReportService.getCrashReportDetail(crashNumber).pipe(
                map(crashReportDetail => {
                  this.store.dispatch(CrashReportActions.setIsNewCrashReport({ isNewCrashReport: false }));
                  this.store.dispatch(CrashReportActions.setNewCrashReportFormValue({ newCrashReportFormValue: null }));
                  return CrashReportActions.crashReportSummarySelected({
                    crashReportSummary: {
                      nopd_item_num: crashReportDetail['report_num_2'],
                      oss_id: crashReportDetail['crash_num'],
                      status: crashReportDetail['report_status'],
                      agent: null,
                      date: crashReportDetail['crash_date'],
                      last_update: crashReportDetail['date_changed'],
                      schema_version: crashReportDetail['rev_num'],
                      rule_set_version: crashReportDetail['rule_set_version'],
                    } as CrashReportSummary,
                  });
                }),
                catchError(error => this.serverErrorService.handleError(error)),
                finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
              );
            }
            return this.serverErrorService.handleError(error);
          }),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
        );
      })
    );
  });

  // these effects are used to approve and reject submitted crash reports and navigate from review container to non review container and back again
  navigateToCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.navigateToCrashReportDetail),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectNeedsReviewCrashReportSummary)),
      switchMap(([action, needsReviewCrashReportSummary]) => {
        // set crash report summary to null cause the crash report detail container waits till the crash report detail, schema, and rule set are loaded
        // before building the form -- we will load the updated crash report detail later in the effect
        this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
        return this.crashReportService.getCrashReportDetail(action.crashReportNumber).pipe(
          mergeMap(crashReportDetail => {
            const crashReportSummary = this.mapSupervisorCrashReportSummaryToCrashReportSummary(needsReviewCrashReportSummary);
            return [CrashReportActions.crashReportSummarySelected({ crashReportSummary }), CrashReportActions.crashReportDetailLoaded({ crashReportDetail })];
          })
        );
      })
    );
  });

  navigateToNeedsReviewCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.navigateToNeedsReviewCrashReportDetail),
      switchMap(action => {
        // set crash report summary to null cause the crash report detail container waits till the crash report detail, schema, and rule set are loaded
        // before building the form -- we will load the updated crash report detail later in the effect
        this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
        return this.crashReportService.getCrashReportDetail(action.crashReportNumber).pipe(
          mergeMap(crashReportDetail => {
            return [
              CrashReportActions.crashReportSummarySelected({ crashReportSummary: null }),
              CrashReportActions.crashReportDetailLoaded({ crashReportDetail }),
            ];
          })
        );
      })
    );
  });

  updateItemNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.updateItemNumber),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        return this.crashReportService
          .updateCrashReportDetail(this.buildCrashReportDetailUpdateRequest(crashReportSummary, null, action), action.metaData)
          .pipe(
            tap(() => this.formService.updateIsDefaultValueOnFormItemMetaData(action.metaData)),
            tap(() => this.formService.updateFormItemOnFormItemMetaDataAndValue(action.metaData, action.value, true)),
            map(() => CrashReportActions.itemNumberUpdated({ updateWasSuccessful: true })),
            catchError((error: HttpErrorResponse) => {
              this.store.dispatch(CrashReportActions.itemNumberUpdated({ updateWasSuccessful: false }));
              return this.serverErrorService.handleError(error);
            })
          );
      })
    );
  });

  // private methods
  private isDatabaseError(errorResponse: HttpErrorResponse): boolean {
    // if the user tries to set the vehicle owner state to null or undefined or empty string,
    // they get an error response with a message property that says 'value should be an array of strings'

    // all other database errors (i've seen so far) return an http error response object with a
    // error property that points to an object with a http_status property that is 500 and an err_code

    // we need to check for both types of errors so we can display the correct error message to the user

    const errorMessages = ['value should be an array of strings'];

    return (
      (CommonUtils.isDefined(errorResponse.message) && errorMessages.includes(errorResponse.message)) ||
      (CommonUtils.isDefined(errorResponse.error['http_status']) &&
        errorResponse.error['http_status'] === 500 &&
        CommonUtils.isDefined(errorResponse.error['err_code']) &&
        StringUtils.isString(errorResponse.error['err_code']) &&
        errorResponse.error['err_code'].startsWith('da'))
    );
  }

  private buildCrashReportDetailUpdateRequest(
    crashReportSummary: CrashReportSummary,
    needsReviewCrashReportSummary: SupervisorCrashReportSummary,
    action: { metaData: FormItemMetaData; value: unknown }
  ): CrashReportDetailUpdateRequest {
    // get the crash number
    let crashNumber: string;
    switch (true) {
      case CommonUtils.isDefined(crashReportSummary): {
        crashNumber = crashReportSummary.oss_id;
        break;
      }
      case CommonUtils.isDefined(needsReviewCrashReportSummary): {
        crashNumber = needsReviewCrashReportSummary.oss_id;
        break;
      }
      default: {
        throw new TechnicalException('crash report summary and needs review crash report summary cannot both be null');
      }
    }
    if (CommonUtils.isNullOrUndefined(crashNumber)) {
      throw new TechnicalException('crash number cannot be null');
    }

    // get keys
    // use the index -- not the api index -- cause the api index changes and the index does not
    const reviewSchemaFormItemKeyNonReviewKeysMap = new Map<string, { schemaGroupKey: string; subGroupKey: string; sectionKey: string }>([
      ['CRASH-0007', { schemaGroupKey: 'crash', subGroupKey: 'crash', sectionKey: 'crashsection1' }],
      ['NARR-0001', { schemaGroupKey: 'narrdetails', subGroupKey: 'narr', sectionKey: 'narrsection1' }],
      ['NARR-0002', { schemaGroupKey: 'narrdetails', subGroupKey: 'narr', sectionKey: 'narrsection2' }],
    ]);

    const getKey = (formItemCompositeKeyParentFormGroupMap: Map<string, FormGroup>, formItemMetaData: FormItemMetaData): string => {
      if (CommonUtils.isDefined(needsReviewCrashReportSummary) && CommonUtils.isNullOrUndefined(crashReportSummary)) {
        return OssOptional.ofNullable(reviewSchemaFormItemKeyNonReviewKeysMap.get(formItemMetaData.key))
          .map(nonReviewKeysMap => {
            switch (true) {
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap: {
                return nonReviewKeysMap.schemaGroupKey;
              }
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap: {
                return nonReviewKeysMap.subGroupKey;
              }
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSectionFormGroupMap: {
                return nonReviewKeysMap.sectionKey;
              }
              default: {
                throw new TechnicalException('form item composite key parent form group map must be one of the three form group maps');
              }
            }
          })
          .orElseThrow(() => new TechnicalException(`key ${formItemMetaData.key} not found in review schema form item key non review keys map`));
      }

      const { schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, fieldName } = formItemMetaData;
      const compositeKey = this.keyService.getFormItemCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, fieldName);
      return OssOptional.ofNullable(formItemCompositeKeyParentFormGroupMap.get(compositeKey))
        .map(formGroup => formGroup.value[FormConstants.META_DATA][FormConstants.KEY])
        .orElseThrow(() => new TechnicalException(`form group with key ${compositeKey} not found`));
    };

    const schemaGroupKey = getKey(this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap, action.metaData);
    const subGroupKey = getKey(this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap, action.metaData);
    const sectionKey = getKey(this.mapService.formItemCompositeKeyParentSectionFormGroupMap, action.metaData);
    const formKey = action.metaData.key;

    // get indexes -- use api indexes
    const schemaGroupApiIndex = action.metaData.schemaGroupApiIndex;
    const subGroupApiIndex = action.metaData.subGroupApiIndex;
    const sectionApiIndex = 0;

    // get the value -- if the form item type is a button group or ecrash ref, the value is an array and we just want the first element
    const value =
      [FormItemType.BUTTON_GROUP, FormItemType.ECRASH_REF].includes(action.metaData.type) && CommonUtils.isArray(action.value) ? action.value[0] : action.value;

    // build the request
    return {
      crash_num: crashNumber,
      form_key: formKey,
      group_key: schemaGroupKey,
      subgroup_key: subGroupKey,
      section_key: sectionKey,
      group_index: schemaGroupApiIndex,
      subgroup_index: subGroupApiIndex,
      section_index: sectionApiIndex,
      value,
    };
  }

  private responseIsFromServiceWorker(response: unknown): boolean {
    CommonUtils.assert(CommonUtils.isDefined(response) && ObjectUtils.isObject(response), 'response must be a non-null object');
    return CommonUtils.isDefined(response['serviceWorkerMessage']);
  }

  private mapSupervisorCrashReportSummaryToCrashReportSummary(supervisorCrashReportSummary: SupervisorCrashReportSummary): CrashReportSummary {
    return {
      agent: supervisorCrashReportSummary.agent,
      date: supervisorCrashReportSummary.date,
      last_update: supervisorCrashReportSummary.last_update,
      nopd_item_num: supervisorCrashReportSummary.nopd_item_num,
      oss_id: supervisorCrashReportSummary.oss_id,
      rule_set_version: supervisorCrashReportSummary.rule_set_version,
      schema_version: supervisorCrashReportSummary.schema_version,
      status: supervisorCrashReportSummary.status,
    };
  }

  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;
  private readonly BALTIMORE_SERVICE_LOCATION_ID = 2;

  private readonly SERVICE_LOCATION_ID_ITEM_NUMBER_PROPERTY_MAP = new Map<number, string>([
    [this.NEW_ORLEANS_SERVICE_LOCATION_ID, 'nopd_item_num'],
    [this.BALTIMORE_SERVICE_LOCATION_ID, 'nopd_item_num'],
  ]);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly crashReportService: CrashReportService,
    private readonly schemaService: SchemaService,
    private readonly ruleSetService: RuleSetService,
    private readonly router: Router,
    private readonly serverErrorService: ServerErrorService,
    private readonly formService: CrashReportDetailFormService,
    private readonly snackbarService: SnackbarService,
    private readonly networkStatusSerivce: NetworkStatusService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly posthogService: PosthogService,
    private readonly supervisorService: SupervisorService,
    private readonly reviewerService: ReviewerService
  ) {}
}
