import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { ServiceLocation } from '../../../data-model/models/service-location.model';

@Injectable({ providedIn: 'root' })
export class ServiceLocationService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  getServiceLocations(): Observable<ServiceLocation[]> {
    return this.apiGateway.get('oss/accounts/serviceLocations', null);
  }
}
