import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { CrashReportSummaryFilter, getNulledOutCrashReportSummaryFilter } from '../../../data-model/models/crash-report-summary-filter.model';
import { SupervisorCrashReportSummaryResponse } from '../../../data-model/models/supevisor-crash-report-summary-response.model';
import { CrashReportSummaryListTab } from '../../../data-model/types/crash-report-summary-list-tab.type';

@Injectable({ providedIn: 'root' })
export class ReviewerService {
  private readonly BASE_URL = 'oss/reviewer';

  constructor(private readonly apiGateway: ApiGatewayService) {}

  getSupervisorCrashReportSummaries(
    serviceLocationId: number,
    tab: Omit<CrashReportSummaryListTab, 'inProgress' | 'submitted' | 'myApproved' | 'myRejected'>,
    limit: number = 1000,
    offset: number = 0,
    filter: CrashReportSummaryFilter = getNulledOutCrashReportSummaryFilter()
  ): Observable<SupervisorCrashReportSummaryResponse> {
    let statuses: string[];
    let hydrateReviews = false;
    let hydrateECrashSubmissionStatus = false;

    switch (tab) {
      case 'needsReview':
        statuses = ['secondary_qc_check'];
        hydrateReviews = true;
        break;
      case 'rejected':
        statuses = ['secondary_incomplete'];
        hydrateReviews = true;
        break;
      case 'approved':
        statuses = ['ready', 'submitted'];
        hydrateECrashSubmissionStatus = true;
        break;
      default:
        throw new UnsupportedOperationException(`Unsupported tab: ${tab}`);
    }

    const additionalHeaders = { ETag: StringUtils.EMPTY };

    return this.apiGateway.get(
      `${this.BASE_URL}/reports`,
      null,
      true,
      additionalHeaders,
      this.buildUrlSearchParams(serviceLocationId, limit, offset, statuses, hydrateReviews, hydrateECrashSubmissionStatus, filter)
    );
  }

  approveCrashReport(crashReportNumber: string, serviceLocationId: number): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, { crash_num: crashReportNumber, is_accepted: true, service_location_id: serviceLocationId });
  }

  rejectCrashReport(crashReportNumber: string, notes: string, serviceLocationId: number): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, {
      crash_num: crashReportNumber,
      is_accepted: false,
      notes: notes,
      status_change_notes: notes,
      service_location_id: serviceLocationId,
    });
  }

  private buildUrlSearchParams(
    serviceLocationId: number,
    limit: number,
    offset: number,
    statuses: string[],
    hydrateReviews: boolean,
    hydrateECrashSubmissionStatus: boolean,
    filter: CrashReportSummaryFilter
  ): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('service_location_id', serviceLocationId.toString());
    urlSearchParams.append('limit', limit.toString());
    urlSearchParams.append('offset', offset.toString());
    urlSearchParams.append('hydrate_reviews', hydrateReviews.toString());
    urlSearchParams.append('hydrate_ecrash_submission_status', hydrateECrashSubmissionStatus.toString());

    statuses.forEach(status => urlSearchParams.append('status', status));

    [
      { key: 'item_number', value: filter?.itemNumber },
      { key: 'agent', value: filter?.agent },
      { key: 'start_date', value: filter?.startDate },
      { key: 'end_date', value: filter?.endDate },
      { key: 'start_time', value: filter?.startTime },
      { key: 'end_time', value: filter?.endTime },
      { key: 'location', value: filter?.location },
    ]
      .filter(({ value }) => CommonUtils.isDefinedAndNonEmptyString(value))
      .forEach(({ key, value }) => urlSearchParams.append(key, value));

    return urlSearchParams;
  }
}
