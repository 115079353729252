import { Injectable } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { tap } from 'rxjs/operators';
import { CustomSnackbarComponent } from '../../../../components/snackbar/custom-snackbar/custom-snackbar.component';
import { OssOptional } from '../../../../utils/oss-optional';
import { SnackbarActions } from '../actions';

@Injectable({ providedIn: 'root' })
export class SnackbarEffects {
  showSnackbar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SnackbarActions.showSnackbar),
        tap(() => this.snackBar.dismiss()),
        tap(action => {
          const duration = OssOptional.ofNullable(action.time).orElse(3000);
          this.snackBar.openFromComponent(CustomSnackbarComponent, {
            duration,
            horizontalPosition: 'center',
            verticalPosition: 'top',
            data: { message: action.message, level: action.level, duration },
          });
        })
      );
    },
    { dispatch: false }
  );

  hideSnackbar$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(SnackbarActions.hideSnackbar),
        tap(() => this.snackBar.dismiss())
      );
    },
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private snackBar: MatSnackBar
  ) {}
}
