import { ActionReducer, MetaReducer } from '@ngrx/store';
import { initialAuthState } from '../../modules/auth/store/reducers/auth.reducer';
import { AuthState } from '../../modules/auth/store/state/auth.state';
import { initialActivityState } from '../../modules/crash-report/store/reducers/activity.reducer';
import { initialCrashReportState } from '../../modules/crash-report/store/reducers/crash-report.reducer';
import { initalECrashState } from '../../modules/crash-report/store/reducers/e-crash.reducer';
import { initialNavigationState } from '../../modules/crash-report/store/reducers/navigation.reducer';
import { initialRuleSetState } from '../../modules/crash-report/store/reducers/rule-set.reducer';
import { initialSchemaState } from '../../modules/crash-report/store/reducers/schema.reducer';
import { initialServiceLocationState } from '../../modules/crash-report/store/reducers/service-location.reducer';
import { initialUnitState } from '../../modules/crash-report/store/reducers/unit.reducer';
import { UnitState } from '../../modules/crash-report/store/state/unit.state';
import { LocalStorageConstants } from '../../modules/shared/constants/local-storage.constants';
import { initialLoadingState } from '../../modules/shared/store/reducers/loading.reducer';
import { CommonUtils } from '../../modules/shared/utils/common-utils';
import { OssOptional } from '../../modules/shared/utils/oss-optional';
import { AppState } from '../state/app.state';

export function initStateFromLocalStorage(reducer: ActionReducer<AppState>): ActionReducer<AppState> {
  return function (state, action) {
    if (action.type !== '[App] Reset' && action.type !== '@ngrx/store/init') {
      return reducer(state, action);
    }

    const currentUserString = localStorage.getItem(LocalStorageConstants.USER_KEY);
    const apiTokenString = localStorage.getItem(LocalStorageConstants.API_TOKEN_KEY);
    const serviceLocationString = localStorage.getItem(LocalStorageConstants.SERVICE_LOCATION_KEY);
    const unitString = localStorage.getItem(LocalStorageConstants.UNIT_KEY);
    const assignmentIdString = localStorage.getItem(LocalStorageConstants.ASSIGNMENT_ID_KEY);

    if (
      CommonUtils.isNullOrUndefined(currentUserString) ||
      CommonUtils.isNullOrUndefined(apiTokenString) ||
      CommonUtils.isNullOrUndefined(serviceLocationString)
    ) {
      return reducer(state, action);
    }

    const apiToken = apiTokenString;
    const currentUser = JSON.parse(currentUserString);
    const authState: AuthState = { ...initialAuthState, apiToken, currentUser };

    const unit = JSON.parse(OssOptional.ofNullable(unitString).orElseNull());
    const assignmentId = JSON.parse(OssOptional.ofNullable(assignmentIdString).orElseNull());
    const unitState: UnitState = { ...initialUnitState, unit, assignmentId };

    const serviceLocation = JSON.parse(serviceLocationString);
    const serviceLocationState = { ...initialServiceLocationState, serviceLocation };

    const newState: AppState = {
      ...state,

      // slices of state that have been overridden with local storage values
      auth: authState,
      unit: unitState,
      serviceLocation: serviceLocationState,

      // slices of state with initial values
      crashReport: { ...initialCrashReportState },
      loading: { ...initialLoadingState },
      activity: { ...initialActivityState },
      schema: { ...initialSchemaState },
      ruleSet: { ...initialRuleSetState },
      eCrash: { ...initalECrashState },
      navigation: { ...initialNavigationState },
    };

    return newState;
  };
}

export const metaReducers: MetaReducer<AppState>[] = [initStateFromLocalStorage];
