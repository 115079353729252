import { createReducer, on } from '@ngrx/store';
import { ServiceLocationActions } from '../actions';
import { ServiceLocationState } from '../state/service-location.state';

export const initialServiceLocationState: ServiceLocationState = {
  serviceLocation: null,
  serviceLocations: [],
};

export const serviceLocationReducer = createReducer(
  initialServiceLocationState,
  on(ServiceLocationActions.serviceLocationsLoaded, (state, action): ServiceLocationState => ({ ...state, serviceLocations: action.serviceLocations })),
  on(ServiceLocationActions.serviceLocationSelected, (state, action): ServiceLocationState => ({ ...state, serviceLocation: action.serviceLocation })),
  on(ServiceLocationActions.resetServiceLocationState, (state): ServiceLocationState => ({ ...state, ...initialServiceLocationState }))
);
