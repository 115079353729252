/* eslint-disable @ngrx/avoid-dispatching-multiple-actions-sequentially */
/* eslint-disable @ngrx/no-dispatch-in-effects */
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { catchError, debounceTime, filter, finalize, forkJoin, map, mergeMap, Observable, of, scan, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { UnsupportedOperationException } from '../../../shared/data-model/models/unsupported-operation-exception.model';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { NetworkStatusService } from '../../../shared/services/network-status/network-status.service';
import { PosthogService } from '../../../shared/services/posthog/posthog.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CollectionUtils } from '../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { StringBuilder } from '../../../shared/utils/string-builder';
import { StringUtils } from '../../../shared/utils/string-utils';
import { FormConstants } from '../../constants/form.constants';
import { FormItemType } from '../../data-model/enums/form-item-type.enum';
import { AddCrashReportDetailRequest } from '../../data-model/models/add-crash-report-detail-request.model';
import { CrashReportDetailBulkUpdateRequest } from '../../data-model/models/crash-report-detail-bulk-update-request.model';
import { CrashReportDetailUpdateRequest } from '../../data-model/models/crash-report-detail-update-request.model';
import { CrashReportDetail, mapCrashReportDetailToCrashReportSummary } from '../../data-model/models/crash-report-detail.model';
import { CrashReportSummaryResponse } from '../../data-model/models/crash-report-summary-response.model';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { FormItemMetaData } from '../../data-model/models/form-item-meta-data.model';
import { SupervisorCrashReportSummaryResponse } from '../../data-model/models/supevisor-crash-report-summary-response.model';
import { CrashReportService } from '../../services/api/crash-report/crash-report.service';
import { ReviewerService } from '../../services/api/reviewer/reviewer.service';
import { RuleSetService } from '../../services/api/rule-set/rule-set.service';
import { SchemaService } from '../../services/api/schema/schema.service';
import { SupervisorService } from '../../services/api/supervisor/supervisor.service';
import { CrashReportDetailFormKeyService } from '../../services/form/crash-report-detail-form-key/crash-report-detail-form-key.service';
import { CrashReportDetailFormService } from '../../services/form/crash-report-detail-form/crash-report-detail-form.service';
import { CrashReportDetailMapService } from '../../services/form/crash-report-detail-map.service';
import { CrashReportActions, RuleSetActions, SchemaActions } from '../actions';
import { initialCrashReportState } from '../reducers/crash-report.reducer';
import { CrashReportSelectors, NavigationSelectors, RuleSetSelectors, SchemaSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class CrashReportEffects {
  loadCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.setCurrentLimit, CrashReportActions.setCurrentOffset, CrashReportActions.setCurrentTab, CrashReportActions.setCurrentFilter),
      filter(action => !CommonUtils.isDefinedAndFalse(action.fetchSummaries)),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsReviewer),
        this.store.select(CrashReportSelectors.selectCurrentLimit),
        this.store.select(CrashReportSelectors.selectCurrentOffset),
        this.store.select(CrashReportSelectors.selectCurrentTab),
        this.store.select(CrashReportSelectors.selectCurrentFilter)
      ),
      switchMap(([action, serviceLocationId, isReviewer, limit, offset, tab, filter]) => {
        const isFilterOrTabChange = action.type === CrashReportActions.setCurrentFilter.type || action.type === CrashReportActions.setCurrentTab.type;

        const adjustedOffset = isFilterOrTabChange ? initialCrashReportState.currentOffset : offset;

        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Loading crash reports...', loaderId }));

        let crashReportSummaries$: Observable<CrashReportSummaryResponse | SupervisorCrashReportSummaryResponse>;
        switch (true) {
          case ['inProgress', 'submitted', 'myRejected', 'myApproved'].includes(tab):
            crashReportSummaries$ = this.crashReportService.getCrashReportSummaries(serviceLocationId, tab, limit, adjustedOffset, filter);
            break;
          case ['needsReview', 'approved', 'rejected'].includes(tab):
            crashReportSummaries$ = isReviewer
              ? this.reviewerService.getSupervisorCrashReportSummaries(serviceLocationId, tab, limit, adjustedOffset, filter)
              : this.supervisorService.getSupervisorCrashReportSummaries(serviceLocationId, tab, limit, adjustedOffset, filter);
            break;
          default:
            throw new UnsupportedOperationException(`tab ${tab} is not supported`);
        }
        return crashReportSummaries$.pipe(
          tap(() => {
            if (isFilterOrTabChange) {
              this.store.dispatch(CrashReportActions.setCurrentOffset({ currentOffset: adjustedOffset, fetchSummaries: false }));
            }
          }),
          map(response =>
            CrashReportActions.crashReportSummariesLoaded({ crashReportSummaries: response.results, numberOfCrashReportSummaries: response.total_results })
          ),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  loadNeedsReviewCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadNeedsReviewCrashReportSummaries),
      withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocationId), this.store.select(AuthSelectors.selectUserIsReviewer)),
      switchMap(([, serviceLocationId, isReviewer]) => {
        const needsReviewCrashReportSummaries$ = isReviewer
          ? this.reviewerService.getSupervisorCrashReportSummaries(serviceLocationId, 'needsReview', 0, 0, null)
          : this.supervisorService.getSupervisorCrashReportSummaries(serviceLocationId, 'needsReview', 0, 0, null);
        return needsReviewCrashReportSummaries$.pipe(
          map(response => CrashReportActions.needsReviewCrashReportSummariesLoaded({ numberOfNeedsReviewCrashReportSummaries: response.total_results })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  loadMyRejectedCrashReportSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadMyRejectedCrashReportSummaries),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([, serviceLocationId]) => {
        return this.crashReportService.getCrashReportSummaries(serviceLocationId, 'myRejected', 0, 0, null).pipe(
          map(response => CrashReportActions.myRejectedCrashReportSummariesLoaded({ numberOfMyRejectedCrashReportSummaries: response.total_results })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  // TODO: add error handling
  // crash report detail effects -- add report, update report, add/remove schema group/sub group, etc.
  loadCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.loadCrashReportDetail),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary),
        this.store.select(SchemaSelectors.selectDefaultSchema),
        this.store.select(SchemaSelectors.selectDefaultSchemaVersion),
        this.store.select(RuleSetSelectors.selectDefaultRuleSet),
        this.store.select(RuleSetSelectors.selectDefaultRuleSetVersion),
        this.store.select(ServiceLocationSelectors.selectServiceLocation),
        this.store.select(CrashReportSelectors.selectCrashReportDetail)
      ),
      tap(([, crashReportSummary, needsReviewCrashReportSummary]) => {
        if (CommonUtils.isNullOrUndefined(crashReportSummary) && CommonUtils.isNullOrUndefined(needsReviewCrashReportSummary)) {
          this.router.navigateByUrl('/crash-report');
          crashReportSummary;
        }
      }),
      filter(
        ([, crashReportSummary, needsReviewCrashReportSummary]) =>
          CommonUtils.isDefined(crashReportSummary) || CommonUtils.isDefined(needsReviewCrashReportSummary)
      ),
      switchMap(
        ([
          ,
          crashReportSummary,
          needsReviewCrashReportSummary,
          defaultSchema,
          defaultSchemaVersion,
          defaultRuleSet,
          defaultRuleSetVersion,
          serviceLocation,
          crashReportDetail,
        ]) => {
          let crashReportId;
          let schemaVersion;
          let ruleSetVersion;

          switch (true) {
            case CommonUtils.isDefined(crashReportSummary) && CommonUtils.isNullOrUndefined(needsReviewCrashReportSummary): {
              crashReportId = crashReportSummary.oss_id;
              schemaVersion = crashReportSummary.schema_version;
              ruleSetVersion = crashReportSummary.rule_set_version;
              break;
            }

            case CommonUtils.isNullOrUndefined(crashReportSummary) && CommonUtils.isDefined(needsReviewCrashReportSummary): {
              crashReportId = needsReviewCrashReportSummary.oss_id;
              schemaVersion = needsReviewCrashReportSummary.schema_version;
              ruleSetVersion = needsReviewCrashReportSummary.rule_set_version;
              break;
            }

            default: {
              throw new TechnicalException('crash report summary and needs review crash report summary cannot both be defined');
            }
          }

          if (this.networkStatusSerivce.isOffline) {
            return of(
              CrashReportActions.crashReportDetailLoaded({ crashReportDetail: {} }),
              SchemaActions.schemaLoaded({ schema: [...defaultSchema], version: defaultSchemaVersion }),
              RuleSetActions.ruleSetLoaded({ ruleSet: [...defaultRuleSet], version: defaultRuleSetVersion })
            );
          }

          // wrap the actions in a setTimeout cause change detection may be running which will result in ExpressionChangedAfterItHasBeenCheckedError
          const loaderId = this.uuidService.generate();
          setTimeout(() => {
            this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Loading crash report...', loaderId }));
            this.store.dispatch(CrashReportActions.setLoaderId({ loaderId }));
          });

          return forkJoin({
            crashReportDetail: OssOptional.ofNullable(crashReportDetail)
              .map(crashReportDetail => of(crashReportDetail))
              .orElse(this.crashReportService.getCrashReportDetail(crashReportId)),
            schemaResponse: this.schemaService.getSchema(schemaVersion, serviceLocation.id),
            ruleSetResponse: this.ruleSetService.getRuleSet(ruleSetVersion, serviceLocation.id),
          }).pipe(
            mergeMap(({ crashReportDetail, schemaResponse, ruleSetResponse }) => {
              return of(
                CrashReportActions.crashReportDetailLoaded({ crashReportDetail }),
                SchemaActions.schemaLoaded(schemaResponse),
                RuleSetActions.ruleSetLoaded(ruleSetResponse)
              );
            })
          );
        }
      )
    );
  });

  updateCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.updateCrashReportDetail),
      scan((udpateActions, updateAction) => [...udpateActions, updateAction], []),
      debounceTime(250),
      filter(updateActions => updateActions.length > 0),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary),
        this.store.select(CrashReportSelectors.selectCurrentTab)
      ),
      switchMap(([updateActions, crashReportSummary, needsReviewCrashReportSummary, currentTab]) => {
        const { META_DATA, KEY } = FormConstants;
        const getKeyOrElseThrow = (formGroup: FormGroup | undefined): string =>
          OssOptional.ofNullable(formGroup)
            .map(formGroup => formGroup.value[META_DATA][KEY])
            .orElseThrow(() => new TechnicalException('form group not found'));

        const serverSideCompositeKeyUpdateActionMap = updateActions.reduce((serverSideCompositeKeyUpdateActionMap, updateAction) => {
          const { schemaGroupDomId, schemaGroupIndex, schemaGroupApiIndex, subGroupDomId, subGroupIndex, subGroupApiIndex, fieldName } = updateAction.metaData;

          // use schema / sub group index to build the form item composite key
          const formItemCompositeKey = this.keyService.getFormItemCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, fieldName);

          const schemaGroupKey = getKeyOrElseThrow(this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap.get(formItemCompositeKey));
          const subGroupKey = getKeyOrElseThrow(this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap.get(formItemCompositeKey));
          const sectionKey = getKeyOrElseThrow(this.mapService.formItemCompositeKeyParentSectionFormGroupMap.get(formItemCompositeKey));
          const pageKey = getKeyOrElseThrow(this.mapService.formItemCompositeKeyParentPageFormGroupMap.get(formItemCompositeKey));

          // use the schema group / sub group api index to build the server side composite key
          const serverSideCompositeKey = new StringBuilder()
            .append(`${schemaGroupKey}:`)
            .append(`${schemaGroupApiIndex}:`)
            .append(`${subGroupKey}:`)
            .append(`${subGroupApiIndex}:`)
            .append(`${sectionKey}:`)
            .append(`${pageKey}:`)
            .append(`${fieldName}`)
            .toString();

          serverSideCompositeKeyUpdateActionMap.set(serverSideCompositeKey, updateAction);
          return serverSideCompositeKeyUpdateActionMap;
        }, new Map());

        const loaderId = this.uuidService.generate();
        if (updateActions.length >= 10) {
          this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report...', loaderId }));
        }

        return this.crashReportService
          .bulkUpdateCrashReportDetail(this.buildCrashReportDetailBulkUpdateRequest(crashReportSummary, needsReviewCrashReportSummary, updateActions))
          .pipe(
            tap(bulkUpdateResponse => {
              const userNavigatedToReviewContainer = ['needsReview', 'rejected', 'myRejected'].includes(currentTab);
              const updateCameFromReviewContainer = userNavigatedToReviewContainer && CommonUtils.isNullOrUndefined(crashReportSummary);

              if (updateCameFromReviewContainer) {
                const errorMessage = Object.entries(bulkUpdateResponse.compositeKeyUpdateDataMap).reduce((errorMessage, [, updateData]) => {
                  if (updateData.updateWasSuccessful) {
                    return errorMessage;
                  }
                  return updateData.message;
                }, '');

                if (CommonUtils.isDefinedAndNonEmptyString(errorMessage)) {
                  this.snackbarService.showSnackbar(errorMessage, 'error');
                }
                return;
              }

              Object.entries(bulkUpdateResponse.compositeKeyUpdateDataMap).forEach(([compositeKey, updateData]) => {
                if (updateData.updateWasSuccessful) {
                  const updateAction = OssOptional.ofNullable(serverSideCompositeKeyUpdateActionMap.get(compositeKey)).orElseThrow(
                    () => new TechnicalException(`update action with composite key ${compositeKey} not found`)
                  );
                  this.formService.updateFormItemOnFormItemMetaDataAndValue(updateAction.metaData, updateAction.value, updateAction.isSetInBackground);
                  return;
                }
                this.snackbarService.showSnackbar(updateData.message, 'error');
              });
            }),
            map(() => CrashReportActions.crashReportDetailUpdated()),
            catchError(error => this.serverErrorService.handleError(error)),
            finalize(() => {
              updateActions.length = 0;
              this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId }));
            })
          );
      })
    );
  });

  addCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.addCrashReportDetail),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocation),
        this.store.select(NavigationSelectors.selectIsRedirectFromActivity),
        this.store.select(AuthSelectors.selectUser)
      ),
      switchMap(([action, serviceLocation, isRedirectFromActvity, user]) => {
        const request: AddCrashReportDetailRequest = {
          local_rpt_num: action.newCrashReportFormValue.itemNumber,
          service_location_id: serviceLocation.id,
          state_inner_rpt_num: null,
        };

        const addCrashReportDetailLoaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Adding crash report...', loaderId: addCrashReportDetailLoaderId }));

        const captureItemCreatedEventAndSetState = (
          crashReportDetail: CrashReportDetail,
          setIsNewCrashReportToFalse: boolean = false,
          setNewCrashReportFormValueToNull: boolean = false
        ): Action => {
          const crashReportSummary = mapCrashReportDetailToCrashReportSummary(crashReportDetail);
          const updateCrashReportDetailLoaderId = this.uuidService.generate();

          this.posthogService.captureItemCreatedEvent(crashReportDetail['crash_num'] as string, user.username, serviceLocation.id);

          // set is new crash report to false if the user clicked an existing report in the activity table
          if (setIsNewCrashReportToFalse) {
            this.store.dispatch(CrashReportActions.setIsNewCrashReport({ isNewCrashReport: false }));
          }

          // null out the new crash report form value if the user clicked an existing report in the activity table
          const newCrashReportFormValue = setNewCrashReportFormValueToNull ? null : action.newCrashReportFormValue;
          this.store.dispatch(CrashReportActions.setNewCrashReportFormValue({ newCrashReportFormValue }));

          this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Updating crash report...', loaderId: updateCrashReportDetailLoaderId }));
          this.store.dispatch(CrashReportActions.setLoaderId({ loaderId: updateCrashReportDetailLoaderId }));
          this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail }));
          return CrashReportActions.setCrashReportSummary({ crashReportSummary });
        };

        const hideLoadingIndicatorAndHandleError = (error: HttpErrorResponse): Observable<Action> => {
          this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId: addCrashReportDetailLoaderId }));
          return this.serverErrorService.handleError(error);
        };

        return this.crashReportService.addCrashReportDetail(request).pipe(
          map(response => captureItemCreatedEventAndSetState(response.crash_report)),
          catchError(error => {
            if (
              (OssOptional.ofNullable(error.error.err_code).orElseNull() === 'crv2-0010' ||
                OssOptional.ofNullable(error.error.message).orElseNull() === 'duplicate item num') &&
              isRedirectFromActvity
            ) {
              const errorObject = OssOptional.ofNullable(error.error).orElseThrow(() => new TechnicalException('error object does not exist'));
              const results = OssOptional.ofNullable(errorObject.results).orElseThrow(() => new TechnicalException('results does not exist on error object'));
              const crashNumber = OssOptional.ofNullable(results.crash_num).orElseThrow(
                () => new TechnicalException('crash_num does not exist on error.results object')
              );
              return this.crashReportService.getCrashReportDetail(crashNumber).pipe(
                map(crashReportDetail => captureItemCreatedEventAndSetState(crashReportDetail, true, true)),
                catchError(error => hideLoadingIndicatorAndHandleError(error))
              );
            }
            return hideLoadingIndicatorAndHandleError(error);
          })
        );
      })
    );
  });

  // these effects are used to approve and reject submitted crash reports and navigate from review container to non review container and back again
  navigateToCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.navigateToCrashReportDetail),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary)),
      switchMap(([action, needsReviewCrashReportSummary]) => {
        // set crash report summary to null cause the crash report detail container waits till the crash report detail, schema, and rule set are loaded
        // before building the form -- we will load the updated crash report detail later in the effect
        this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
        return this.crashReportService.getCrashReportDetail(action.crashReportNumber).pipe(
          mergeMap(crashReportDetail => {
            return [
              CrashReportActions.setCrashReportSummary({ crashReportSummary: needsReviewCrashReportSummary }),
              CrashReportActions.crashReportDetailLoaded({ crashReportDetail }),
            ];
          })
        );
      })
    );
  });

  navigateToNeedsReviewCrashReportDetail$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.navigateToNeedsReviewCrashReportDetail),
      switchMap(action => {
        // set crash report summary to null cause the crash report detail container waits till the crash report detail, schema, and rule set are loaded
        // before building the form -- we will load the updated crash report detail later in the effect
        this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
        return this.crashReportService.getCrashReportDetail(action.crashReportNumber).pipe(
          mergeMap(crashReportDetail => {
            return [CrashReportActions.setCrashReportSummary({ crashReportSummary: null }), CrashReportActions.crashReportDetailLoaded({ crashReportDetail })];
          })
        );
      })
    );
  });

  updateItemNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.updateItemNumber),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        return this.crashReportService.updateCrashReportDetail(this.buildCrashReportDetailUpdateRequest(crashReportSummary, null, action)).pipe(
          // value changes are now applied on the back end when a crash report, group, or sub group is added
          // leaving the following code here for reference
          // tap(() => this.formService.updateIsDefaultValueOnFormItemMetaData(action.metaData)),
          tap(() => this.formService.updateFormItemOnFormItemMetaDataAndValue(action.metaData, action.value, true)),
          map(() => CrashReportActions.itemNumberUpdated({ updateWasSuccessful: true })),
          catchError((error: HttpErrorResponse) => {
            this.store.dispatch(CrashReportActions.itemNumberUpdated({ updateWasSuccessful: false }));
            return this.serverErrorService.handleError(error);
          })
        );
      })
    );
  });

  deleteCrashReport$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportActions.deleteCrashReport),
      switchMap(action => {
        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Deleting crash report...' }));
        return this.crashReportService.deleteCrashReportDetail(action.crashNumber).pipe(
          map(result => CrashReportActions.crashReportDeleted({ deleteWasSuccessful: CommonUtils.isDefinedAndTrue(result.success) })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
        );
      })
    );
  });

  crashReportDeleted$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(CrashReportActions.crashReportDeleted),
        tap(action => {
          if (action.deleteWasSuccessful) {
            this.snackbarService.showSnackbar('Crash report deleted successfully.', 'success');
            this.router.navigate(['..'], { relativeTo: this.route, queryParams: { view: 'in-progress-submitted-tab-group' } });
            return;
          }
          this.snackbarService.showSnackbar('An error occurred while deleting the crash report. Please try again.', 'error');
        })
      );
    },
    { dispatch: false }
  );

  /**
   * @deprecated -- this effect is not used anymore as crash reports are updated in bulk
   */
  private isDatabaseError(errorResponse: HttpErrorResponse): boolean {
    // if the user tries to set the vehicle owner state to null or undefined or empty string,
    // they get an error response with a message property that says 'value should be an array of strings'

    // all other database errors (i've seen so far) return an http error response object with a
    // error property that points to an object with a http_status property that is 500 and an err_code

    // we need to check for both types of errors so we can display the correct error message to the user

    const errorMessages = ['value should be an array of strings'];

    return (
      (CommonUtils.isDefined(errorResponse.message) && errorMessages.includes(errorResponse.message)) ||
      (CommonUtils.isDefined(errorResponse.error['http_status']) &&
        errorResponse.error['http_status'] === 500 &&
        CommonUtils.isDefined(errorResponse.error['err_code']) &&
        StringUtils.isString(errorResponse.error['err_code']) &&
        errorResponse.error['err_code'].startsWith('da'))
    );
  }

  private buildCrashReportDetailUpdateRequest(
    crashReportSummary: CrashReportSummary,
    needsReviewCrashReportSummary: CrashReportSummary,
    action: { metaData: FormItemMetaData; value: unknown }
  ): CrashReportDetailUpdateRequest {
    // get the crash number
    let crashNumber: string;
    switch (true) {
      case CommonUtils.isDefined(crashReportSummary): {
        crashNumber = crashReportSummary.oss_id;
        break;
      }
      case CommonUtils.isDefined(needsReviewCrashReportSummary): {
        crashNumber = needsReviewCrashReportSummary.oss_id;
        break;
      }
      default: {
        throw new TechnicalException('crash report summary and needs review crash report summary cannot both be null');
      }
    }
    if (CommonUtils.isNullOrUndefined(crashNumber)) {
      throw new TechnicalException('crash number cannot be null');
    }

    // get keys
    // use the index -- not the api index -- cause the api index changes and the index does not
    const reviewSchemaFormItemKeyNonReviewKeysMap = new Map<string, { schemaGroupKey: string; subGroupKey: string; sectionKey: string; pageKey: string }>([
      ['CRASH-0007', { schemaGroupKey: 'crash', subGroupKey: 'crash', sectionKey: 'crashsection1', pageKey: 'crashsection1page1' }],
      ['NARR-0001', { schemaGroupKey: 'narrdetails', subGroupKey: 'narr', sectionKey: 'narrsection1', pageKey: 'narrsection1page1' }],
      ['NARR-0002', { schemaGroupKey: 'narrdetails', subGroupKey: 'narr', sectionKey: 'narrsection2', pageKey: 'narrsection2page1' }],
      ['CRASH-0548', { schemaGroupKey: 'hidden', subGroupKey: 'hidden', sectionKey: 'hidden', pageKey: 'hidden' }],
      ['CRASH-0550', { schemaGroupKey: 'hidden', subGroupKey: 'hidden', sectionKey: 'hidden', pageKey: 'hidden' }],
      ['CRASH-0547', { schemaGroupKey: 'hidden', subGroupKey: 'hidden', sectionKey: 'hidden', pageKey: 'hidden' }],
    ]);

    const getKey = (formItemCompositeKeyParentFormGroupMap: Map<string, FormGroup>, formItemMetaData: FormItemMetaData): string => {
      if (CommonUtils.isDefined(needsReviewCrashReportSummary) && CommonUtils.isNullOrUndefined(crashReportSummary)) {
        return OssOptional.ofNullable(reviewSchemaFormItemKeyNonReviewKeysMap.get(formItemMetaData.key))
          .map(nonReviewKeysMap => {
            switch (true) {
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap: {
                return nonReviewKeysMap.schemaGroupKey;
              }
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap: {
                return nonReviewKeysMap.subGroupKey;
              }
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentSectionFormGroupMap: {
                return nonReviewKeysMap.sectionKey;
              }
              case formItemCompositeKeyParentFormGroupMap === this.mapService.formItemCompositeKeyParentPageFormGroupMap: {
                return nonReviewKeysMap.pageKey;
              }
              default: {
                throw new TechnicalException('form item composite key parent form group map must be one of the three form group maps');
              }
            }
          })
          .orElseThrow(() => new TechnicalException(`key ${formItemMetaData.key} not found in review schema form item key non review keys map`));
      }

      const { schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, fieldName } = formItemMetaData;
      const compositeKey = this.keyService.getFormItemCompositeKey(schemaGroupDomId, schemaGroupIndex, subGroupDomId, subGroupIndex, fieldName);
      return OssOptional.ofNullable(formItemCompositeKeyParentFormGroupMap.get(compositeKey))
        .map(formGroup => formGroup.value[FormConstants.META_DATA][FormConstants.KEY])
        .orElseThrow(() => new TechnicalException(`form group with key ${compositeKey} not found`));
    };

    const schemaGroupKey = getKey(this.mapService.formItemCompositeKeyParentSchemaGroupFormGroupMap, action.metaData);
    const subGroupKey = getKey(this.mapService.formItemCompositeKeyParentSubGroupFormGroupMap, action.metaData);
    const sectionKey = getKey(this.mapService.formItemCompositeKeyParentSectionFormGroupMap, action.metaData);
    const pageKey = getKey(this.mapService.formItemCompositeKeyParentPageFormGroupMap, action.metaData);
    const formKey = action.metaData.key;

    // get indexes -- use api indexes
    const schemaGroupApiIndex = action.metaData.schemaGroupApiIndex;
    const subGroupApiIndex = action.metaData.subGroupApiIndex;

    // get the value -- if the form item type is a button group or ecrash ref, the value is an array and we just want the first element
    let value =
      [FormItemType.BUTTON_GROUP, FormItemType.ECRASH_REF].includes(action.metaData.type) && CommonUtils.isArray(action.value) ? action.value[0] : action.value;

    // button group with is multi set to true was sending an empty string to the back end instead of null
    // when all the buttons were unselected -- map this to null as it was causing validation errors
    if (value === StringUtils.EMPTY) {
      value = null;
    }

    // build the request
    return {
      crash_num: crashNumber,
      form_key: formKey,
      group_key: schemaGroupKey,
      page_key: pageKey,
      subgroup_key: subGroupKey,
      section_key: sectionKey,
      group_index: schemaGroupApiIndex,
      subgroup_index: subGroupApiIndex,
      value,
    };
  }

  private buildCrashReportDetailBulkUpdateRequest(
    crashReportSummary: CrashReportSummary,
    needsReviewCrashReportSummary: CrashReportSummary,
    actions: { metaData: FormItemMetaData; value: unknown }[]
  ): CrashReportDetailBulkUpdateRequest {
    const updatesWithCrashNumber = actions.map(action => this.buildCrashReportDetailUpdateRequest(crashReportSummary, needsReviewCrashReportSummary, action));
    const { crash_num: crashNumber } = CollectionUtils.getFirstElement(updatesWithCrashNumber);
    const updatesWithoutCrashNumber = updatesWithCrashNumber.map(update => {
      const clone = { ...update };
      delete clone.crash_num;
      return clone;
    });
    return { crash_num: crashNumber, updates: updatesWithoutCrashNumber } as unknown as CrashReportDetailBulkUpdateRequest;
  }

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly crashReportService: CrashReportService,
    private readonly schemaService: SchemaService,
    private readonly ruleSetService: RuleSetService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly serverErrorService: ServerErrorService,
    private readonly formService: CrashReportDetailFormService,
    private readonly snackbarService: SnackbarService,
    private readonly networkStatusSerivce: NetworkStatusService,
    private readonly mapService: CrashReportDetailMapService,
    private readonly keyService: CrashReportDetailFormKeyService,
    private readonly posthogService: PosthogService,
    private readonly supervisorService: SupervisorService,
    private readonly reviewerService: ReviewerService,
    private readonly uuidService: UuidService
  ) {}
}
