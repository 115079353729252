import { Store } from '@ngrx/store';
import { AuthActions } from '../../modules/auth/store/actions';
import {
  ActivityActions,
  CrashReportActions,
  ECrashActions,
  NavigationActions,
  RuleSetActions,
  SchemaActions,
  ServiceLocationActions,
  UnitActions,
} from '../../modules/crash-report/store/actions';
import { LoadingActions } from '../../modules/shared/store/actions';
import { AppState } from '../state/app.state';

/**
 * Reset the app state to its initial values.
 *
 * @param store - The store to reset.
 * @param [resetAuthState=true] - Whether to reset the auth state.
 * @returns void
 */
export const resetAppState = (store: Store<AppState>, resetAuthState: boolean = true): void => {
  if (resetAuthState) {
    store.dispatch(AuthActions.resetAuthState());
  }
  store.dispatch(UnitActions.resetUnitState());
  store.dispatch(ServiceLocationActions.resetServiceLocationState());
  store.dispatch(CrashReportActions.resetCrashReportState());
  store.dispatch(LoadingActions.resetLoadingState());
  store.dispatch(ActivityActions.resetActivityState());
  store.dispatch(SchemaActions.resetSchemaState());
  store.dispatch(RuleSetActions.resetRuleSetState());
  store.dispatch(ECrashActions.resetECrashState());
  store.dispatch(NavigationActions.resetNavigationState());
};
