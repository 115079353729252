import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { PosthogService } from '../../../shared/services/posthog/posthog.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { ActivityTableRevisionModalComponent } from '../../components/activity/activity-table-revision-modal/activity-table-revision-modal.component';
import { ActivityRecordVerificationStatus } from '../../data-model/enums/activity-record-verification-status.enum';
import { ActivityService } from '../../services/api/activity/activity.service';
import { ActivityActions, NavigationActions } from '../actions';
import { ActivitySelectors, CrashReportSelectors, NavigationSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class ActivityEffects {
  loadActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadActivityRecords, ActivityActions.setLimit, ActivityActions.setOffset, ActivityActions.setVerificationStatus),
      withLatestFrom(
        this.store.select(ActivitySelectors.selectLimit),
        this.store.select(ActivitySelectors.selectOffset),
        this.store.select(ActivitySelectors.selectVerificationStatus),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, limit, offset, verificationStatus, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService.getActivityRecords(limit, offset, verificationStatus, serviceLocationId, userIsSupervisor, userIsAdministrator).pipe(
          map(response => ActivityActions.activityRecordsLoaded({ activityRecords: response.results, numberOfActivityRecords: response.total_results })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  loadUnverifiedActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadUnverifiedActivityRecords),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService
          .getActivityRecords(null, null, ActivityRecordVerificationStatus.UNVERIFIED, serviceLocationId, userIsSupervisor, userIsAdministrator)
          .pipe(
            map(response => ActivityActions.unverifiedActivityRecordsLoaded({ numberOfUnverifiedActivityRecords: response.total_results })),
            catchError(error => this.serverErrorService.handleError(error))
          )
      )
    );
  });

  loadMissingActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadMissingActivityRecords),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService
          .getActivityRecords(null, null, ActivityRecordVerificationStatus.MISSING, serviceLocationId, userIsSupervisor, userIsAdministrator)
          .pipe(
            map(response => ActivityActions.missingActivityRecordsLoaded({ numberOfMissingActivityRecords: response.total_results })),
            catchError(error => this.serverErrorService.handleError(error))
          )
      )
    );
  });

  openRevisionModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.openRevisionModal),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([action, serviceLocationId]) => {
        const data = { activityRecord: action.activityRecord, isInitialVerification: action.isInitialVerification, serviceLocationId };
        return this.dialog
          .open(ActivityTableRevisionModalComponent, { data })
          .afterClosed()
          .pipe(
            filter(result => {
              const userClickedReverify = CommonUtils.isDefinedAndTrue(result?.success);
              if (!userClickedReverify) {
                this.store.dispatch(ActivityActions.loadActivityRecords());
              }
              return userClickedReverify;
            }),
            map(result => ActivityActions.updateItemNumber({ activityRecord: action.activityRecord, itemNumber: result.itemNumber }))
          );
      })
    );
  });

  updateItemNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.updateItemNumber),
      concatLatestFrom(() => this.store.select(ActivitySelectors.selectVerificationStatus)),
      switchMap(([action, verificationStatus]) => {
        const shouldReconcile = verificationStatus === ActivityRecordVerificationStatus.UNVERIFIED;
        return this.activityService.patchActivityRecordItemNumber(action.activityRecord.id, action.itemNumber, shouldReconcile).pipe(
          filter(response => {
            if (CommonUtils.isDefinedAndFalse(response?.success)) {
              this.snackbarService.showSnackbar('Failed to update item number', 'error');
              return false;
            }
            return true;
          }),
          tap(response => {
            if (shouldReconcile) {
              if (response.verification_was_successful) {
                this.snackbarService.showSnackbar('The item number has been successfully verified', 'success');
                this.store.dispatch(ActivityActions.loadUnverifiedActivityRecords());
                this.store.dispatch(ActivityActions.loadMissingActivityRecords());
                this.store.dispatch(ActivityActions.loadActivityRecords());
              } else {
                this.store.dispatch(ActivityActions.openRevisionModal({ activityRecord: action.activityRecord, isInitialVerification: false }));
              }
            }
          }),
          map(() => ActivityActions.itemNumberUpdated({ itemNumber: action.itemNumber })),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  closeItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.closeItem),
      concatLatestFrom(() => this.store.select(NavigationSelectors.selectIsRedirectFromNonActivity)),
      switchMap(([action, isRedirectFromNonActivity]) => {
        const shouldReconcile = isRedirectFromNonActivity;
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ loaderId, message: 'Closing item...' }));
        return this.activityService.closeItem(action.request, shouldReconcile).pipe(
          withLatestFrom(
            this.store.select(CrashReportSelectors.selectCrashReportSummary),
            this.store.select(ServiceLocationSelectors.selectServiceLocationId),
            this.store.select(AuthSelectors.selectUser)
          ),
          tap(([response, crashReportSummary, serviceLocationId, user]) => {
            if (shouldReconcile) {
              if (response.verification_was_successful) {
                this.snackbarService.showSnackbar('The item number has been successfully verified', 'success');
              } else {
                this.snackbarService.showSnackbar('Unable to verify the item number', 'error');
              }
            }

            if (action.request.disposition !== 'RTF') {
              this.posthogService.captureNonReportItemCreatedEvent(
                OssOptional.ofNullable(crashReportSummary)
                  .map(summary => summary.oss_id)
                  .orElse('oss id not available'),
                OssOptional.ofNullable(user)
                  .map(currentUser => currentUser.username)
                  .orElse('username not available'),
                serviceLocationId,
                action.request.disposition
              );
            }

            if (isRedirectFromNonActivity) {
              this.store.dispatch(NavigationActions.navigateFromNonReviewFormToMissingActivityTable());
            } else {
              this.store.dispatch(NavigationActions.navigateFromCloseEventToInProgressMyApprovedList());
            }
          }),
          map(() => ActivityActions.itemClosed()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly serverErrorService: ServerErrorService,
    private readonly activityService: ActivityService,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly posthogService: PosthogService,
    private readonly uuidService: UuidService
  ) {}
}
