import { createReducer, on } from '@ngrx/store';
import { CrashReportActions } from '../actions';
import { CrashReportState } from '../state/crash-report.state';

export const initialCrashReportState: CrashReportState = {
  // selected crash report
  crashReportSummary: null,
  supervisorCrashReportSummary: null,
  crashReportDetail: null,

  // used when creating a new crash report or loading an existing crash report
  newCrashReportFormValue: null,
  isNewCrashReport: false,
  loaderId: null,

  // crash report summaries
  crashReportSummaries: [],
  numberOfCrashReportSummaries: 0,
  currentLimit: 5,
  currentOffset: 0,
  currentTab: 'inProgress',

  // report counts to be displayed in the horizontal nav bar
  numberOfNeedsReviewCrashReportSummaries: 0,
  numberOfMyRejectedCrashReportSummaries: 0,
};

export const crashReportReducer = createReducer(
  initialCrashReportState,

  // selected crash report
  on(CrashReportActions.setCrashReportSummary, (state, action): CrashReportState => ({ ...state, crashReportSummary: action.crashReportSummary })),
  on(
    CrashReportActions.setSupervisorCrashReportSummary,
    (state, action): CrashReportState => ({ ...state, supervisorCrashReportSummary: action.supervisorCrashReportSummary })
  ),
  on(CrashReportActions.crashReportDetailLoaded, (state, action): CrashReportState => ({ ...state, crashReportDetail: action.crashReportDetail })),

  // used when creating a new crash report or loading an existing crash report
  on(
    CrashReportActions.setNewCrashReportFormValue,
    (state, action): CrashReportState => ({ ...state, newCrashReportFormValue: action.newCrashReportFormValue })
  ),
  on(CrashReportActions.setIsNewCrashReport, (state, action): CrashReportState => ({ ...state, isNewCrashReport: action.isNewCrashReport })),
  on(CrashReportActions.setLoaderId, (state, action): CrashReportState => ({ ...state, loaderId: action.loaderId })),

  // crash report summaries
  on(
    CrashReportActions.crashReportSummariesLoaded,
    (state, action): CrashReportState => ({
      ...state,
      crashReportSummaries: action.crashReportSummaries,
      numberOfCrashReportSummaries: action.numberOfCrashReportSummaries,
    })
  ),
  on(CrashReportActions.setCurrentLimit, (state, action): CrashReportState => ({ ...state, currentLimit: action.currentLimit })),
  on(CrashReportActions.setCurrentOffset, (state, action): CrashReportState => ({ ...state, currentOffset: action.currentOffset })),
  on(
    CrashReportActions.setCurrentTab,
    (state, action): CrashReportState => ({
      ...state,
      currentTab: action.tab,
      currentLimit: initialCrashReportState.currentLimit,
      currentOffset: initialCrashReportState.currentOffset,
    })
  ),

  // report counts to be displayed in the horizontal nav bar
  on(
    CrashReportActions.needsReviewCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, numberOfNeedsReviewCrashReportSummaries: action.numberOfNeedsReviewCrashReportSummaries })
  ),
  on(
    CrashReportActions.myRejectedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, numberOfMyRejectedCrashReportSummaries: action.numberOfMyRejectedCrashReportSummaries })
  ),

  // reset state
  on(CrashReportActions.resetCrashReportState, (state): CrashReportState => ({ ...state, ...initialCrashReportState }))
);
