import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CollectionUtils } from '../../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { ECrashValidation } from '../../../data-model/models/e-crash-validation.model';

@Injectable({ providedIn: 'root' })
export class ECrashService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  validateReport(crashNumber: string): Observable<ECrashValidation> {
    return this.apiGateway.post<ECrashValidation>('oss/eCrashAPI/validateReport', { crash_num: crashNumber }).pipe(map(this.mapECrashValidation));
  }

  submitReport(crashNumber: string): Observable<ApiOperationStatus> {
    return this.apiGateway.post('oss/eCrashAPI/submitReport', { crash_num: crashNumber });
  }

  private mapECrashValidation(eCrashValidation: ECrashValidation): ECrashValidation {
    // instantiate a new object to avoid mutating the original object
    // filter out validation errors that do not have a rule id
    // when a report is valid, there will be two validation errors: no warnings found and no errors found
    const updatedECrashValidation = {
      ...eCrashValidation,
      validationErrors: eCrashValidation.validationErrors.filter(validationError => CommonUtils.isDefined(validationError.RuleId)),
    };

    // if there are no format errors and there is one validation error and it is DiagramsMustNotBeEmpty, then remove it and mark the report as valid
    if (
      CommonUtils.isEmpty(updatedECrashValidation.formatErrors) &&
      CommonUtils.hasContent(updatedECrashValidation.validationErrors) &&
      updatedECrashValidation.validationErrors.length === 1 &&
      CollectionUtils.getOnlyElement(updatedECrashValidation.validationErrors).RuleId.includes('DiagramsMustNotBeEmpty')
    ) {
      updatedECrashValidation.validationErrors = [];
      updatedECrashValidation.isValid = true;
    }

    // if there are more than one validation errors and one of them is DiagramsMustNotBeEmpty, then remove it
    else if (CommonUtils.hasContent(updatedECrashValidation.validationErrors) && updatedECrashValidation.validationErrors.length > 1) {
      updatedECrashValidation.validationErrors = updatedECrashValidation.validationErrors.filter(
        validationError => !validationError.RuleId.includes('DiagramsMustNotBeEmpty')
      );
    }

    return updatedECrashValidation;
  }
}
