import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, filter, map, switchMap, tap } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthActions } from '../../../auth/store/actions';
import { AuthSelectors } from '../../../auth/store/selectors';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { NetworkStatusService } from '../../../shared/services/network-status/network-status.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { CollectionUtils } from '../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssStream } from '../../../shared/utils/oss-stream';
import { ServiceLocationService } from '../../services/api/service-location/service-location.service';
import { ServiceLocationActions } from '../actions';

@Injectable()
export class ServiceLocationEffects {
  loadServiceLocations$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ServiceLocationActions.loadServiceLocations),
      filter(() => this.networkStatusSerivce.isOnline),
      switchMap(() =>
        this.serviceLocationService.getServiceLocations().pipe(
          map(serviceLocations => ServiceLocationActions.serviceLocationsLoaded({ serviceLocations })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  clientVersionValidated$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(AuthActions.clientVersionValidated),
        concatLatestFrom(() => this.store.select(AuthSelectors.selectUser)),
        switchMap(([, user]) => {
          if (CommonUtils.isEmpty(user.service_location_ids)) {
            this.snackbarService.showSnackbar('You do not have access to any service locations', 'error');
            return EMPTY;
          }

          return this.serviceLocationService.getServiceLocations().pipe(
            tap(serviceLocations => {
              this.store.dispatch(ServiceLocationActions.serviceLocationsLoaded({ serviceLocations }));

              const serviceLocationId = CollectionUtils.getFirstElement(user.service_location_ids);
              const serviceLocation = OssStream.from(serviceLocations)
                .filter(serviceLocation => serviceLocation.id === serviceLocationId)
                .findFirst()
                .orElseThrow(() => new TechnicalException('Service location not found'));

              this.localStorageService.setServiceLocation(serviceLocation);
              this.store.dispatch(ServiceLocationActions.serviceLocationSelected({ serviceLocation }));

              this.router.navigate(['/crash-report']);
            }),
            catchError(error => this.serverErrorService.handleError(error))
          );
        })
      );
    },
    { dispatch: false }
  );

  signedOut$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AuthActions.signedOut),
      map(() => ServiceLocationActions.serviceLocationSelected({ serviceLocation: null }))
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly serverErrorService: ServerErrorService,
    private readonly serviceLocationService: ServiceLocationService,
    private readonly networkStatusSerivce: NetworkStatusService,
    private readonly snackbarService: SnackbarService,
    private readonly localStorageService: LocalStorageService,
    private readonly store: Store<AppState>,
    private readonly router: Router
  ) {}
}
