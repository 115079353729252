import { createReducer, on } from '@ngrx/store';
import { CrashReportActions } from '../actions';
import { CrashReportState } from '../state/crash-report.state';

export const initialCrashReportState: CrashReportState = {
  // crash report details and summaries
  crashReportDetail: null,
  crashReportSummary: null,
  needsReviewCrashReportSummary: null,
  newCrashReportFormValue: null,
  isNewCrashReport: false,

  // review form -> non-review form -> review form
  isRedirectFromNonReview: false,
  isRedirectFromReview: false,

  // activity list -> non-review-form -> activity list
  isRedirectFromNonActivity: false,
  isRedirectFromActvity: false,

  // non-supervisor crash report summaries
  inProgressCrashReportSummaries: [],
  submittedCrashReportSummaries: [],
  currentOffset: -1,
  currentPage: 'inProgress',
  numberOfSubmittedCrashReportSummaries: -1,
  myApprovedCrashReportSummaries: [],
  myRejectedCrashReportSummaries: [],

  // supervisor crash report summaries
  needsReviewCrashReportSummaries: [],
  approvedCrashReportSummaries: [],
  rejectedCrashReportSummaries: [],
};

export const crashReportReducer = createReducer(
  initialCrashReportState,

  // crash report details and summaries
  on(CrashReportActions.crashReportSummarySelected, (state, action): CrashReportState => ({ ...state, crashReportSummary: action.crashReportSummary })),
  on(
    CrashReportActions.needsReviewCrashReportSummarySelected,
    (state, action): CrashReportState => ({ ...state, needsReviewCrashReportSummary: action.crashReportSummary })
  ),
  on(CrashReportActions.crashReportDetailLoaded, (state, action): CrashReportState => ({ ...state, crashReportDetail: action.crashReportDetail })),
  on(
    CrashReportActions.setNewCrashReportFormValue,
    (state, action): CrashReportState => ({ ...state, newCrashReportFormValue: action.newCrashReportFormValue })
  ),
  on(CrashReportActions.setIsNewCrashReport, (state, action): CrashReportState => ({ ...state, isNewCrashReport: action.isNewCrashReport })),

  // review form -> non-review form -> review form
  on(CrashReportActions.setIsRedirectFromReview, (state, action): CrashReportState => ({ ...state, isRedirectFromReview: action.isRedirectFromReview })),
  on(
    CrashReportActions.setIsRedirectFromNonReview,
    (state, action): CrashReportState => ({ ...state, isRedirectFromNonReview: action.isRedirectFromNonReview })
  ),

  // activity list -> non-review-form -> activity list
  on(CrashReportActions.setIsRedirectFromActvity, (state, action): CrashReportState => ({ ...state, isRedirectFromActvity: action.isRedirectFromActivity })),
  on(
    CrashReportActions.setIsRedirectFromNonActivity,
    (state, action): CrashReportState => ({ ...state, isRedirectFromNonActivity: action.isRedirectFromNonActivity })
  ),

  // non-supervisor crash report summaries
  on(
    CrashReportActions.inProgressCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, inProgressCrashReportSummaries: action.inProgressCrashReportSummaries })
  ),
  on(
    CrashReportActions.submittedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, submittedCrashReportSummaries: action.submittedCrashReportSummaries })
  ),
  on(CrashReportActions.setCurrentPage, (state, action): CrashReportState => ({ ...state, currentPage: action.currentPage })),
  on(CrashReportActions.setCurrentOffset, (state, action): CrashReportState => ({ ...state, currentOffset: action.currentOffset })),
  on(
    CrashReportActions.setNumberOfSubmittedCrashReportSummaries,
    (state, action): CrashReportState => ({ ...state, numberOfSubmittedCrashReportSummaries: action.numberOfSubmittedCrashReportSummaries })
  ),
  on(
    CrashReportActions.myApprovedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, myApprovedCrashReportSummaries: action.myApprovedCrashReportSummaries })
  ),
  on(
    CrashReportActions.myRejectedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, myRejectedCrashReportSummaries: action.myRejectedCrashReportSummaries })
  ),

  // supervisor crash report summaries
  on(
    CrashReportActions.needsReviewCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, needsReviewCrashReportSummaries: action.needsReviewCrashReportSummaries })
  ),
  on(
    CrashReportActions.approvedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, approvedCrashReportSummaries: action.approvedCrashReportSummaries })
  ),
  on(
    CrashReportActions.rejectedCrashReportSummariesLoaded,
    (state, action): CrashReportState => ({ ...state, rejectedCrashReportSummaries: action.rejectedCrashReportSummaries })
  )
);
