import { CrashReportStatus } from '../enums/crash-report-status.enum';
import { CrashReportSummary } from './crash-report-summary.model';

export type CrashReportDetail = Record<string, unknown | unknown[]>;

export const mapCrashReportDetailToCrashReportSummary = (crashReportDetail: CrashReportDetail): CrashReportSummary => {
  const crashReportSummary: CrashReportSummary = {
    agent: null,
    coordinates: crashReportDetail['crash_coordinates'] as string,
    date: new Date(crashReportDetail['crash_date'] as string),
    intersecting_road_name: crashReportDetail['inter_road_name'] as string,
    intersecting_road_type: crashReportDetail['inter_road_type'] as string,
    last_update: new Date(crashReportDetail['date_changed'] as string),
    nopd_item_num: crashReportDetail['report_num_2'] as string,
    oss_id: crashReportDetail['crash_num'] as string,
    primary_road_name: crashReportDetail['pri_road_name'] as string,
    primary_road_type: crashReportDetail['pri_road_type'] as string,
    rule_set_version: crashReportDetail['rule_set_version'] as number,
    schema_version: crashReportDetail['rev_num'] as number,
    status: CrashReportStatus[crashReportDetail['report_status'] as string],
    time: crashReportDetail['crash_time'] as string,
  };
  return crashReportSummary;
};
