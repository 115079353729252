import { createReducer, on } from '@ngrx/store';
import { OssOptional } from '../../utils/oss-optional';
import { LoadingActions } from '../actions';
import { LoadingState } from '../state/loading.state';

export const initialLoadingState: LoadingState = {
  showLoadingIndicator: false,
  loadingMessage: null,
  loaderId: null,
};

export const loadingReducer = createReducer(
  initialLoadingState,
  on(
    LoadingActions.showLoadingIndicator,
    (state, action): LoadingState => ({
      ...state,
      showLoadingIndicator: true,
      loadingMessage: OssOptional.ofNullable(action.message).orElseNull(),
    })
  ),
  on(LoadingActions.hideLoadingIndicator, (state): LoadingState => ({ ...state, showLoadingIndicator: false, loadingMessage: null })),
  on(
    LoadingActions.showLoadingIndicatorWithId,
    (state, action): LoadingState => ({
      ...state,
      showLoadingIndicator: true,
      loadingMessage: OssOptional.ofNullable(action.message).orElseNull(),
      loaderId: action.loaderId,
    })
  ),
  on(
    LoadingActions.hideLoadingIndicatorWithId,
    (state, action): LoadingState =>
      state.loaderId === action.loaderId ? { ...state, showLoadingIndicator: false, loadingMessage: null, loaderId: null } : { ...state }
  ),
  on(LoadingActions.resetLoadingState, (state): LoadingState => ({ ...state, ...initialLoadingState }))
);
