import { createReducer, on } from '@ngrx/store';
import { AuthActions } from '../actions';
import { AuthState } from '../state/auth.state';

export const initialAuthState: AuthState = {
  apiToken: null,
  currentUser: null,
};

export const authReducer = createReducer(
  initialAuthState,
  on(AuthActions.signedIn, (state, action): AuthState => ({ ...state, currentUser: action.currentUser, apiToken: action.apiToken })),
  on(AuthActions.signedOut, (state): AuthState => ({ ...state, currentUser: null, apiToken: null }))
);
