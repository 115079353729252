import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, finalize, map, switchMap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { UnsupportedOperationException } from '../../../shared/data-model/models/unsupported-operation-exception.model';
import { ConfigService } from '../../../shared/services/config/config.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { ECrashService } from '../../services/api/e-crash/e-crash.service';
import { CrashReportApprovalActions, ECrashActions, NavigationActions } from '../actions';
import { CrashReportSelectors } from '../selectors';

@Injectable()
export class ECrashEffects {
  loadValidationObject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ECrashActions.loadValidationObject),
      withLatestFrom(
        this.store.select(CrashReportSelectors.selectCrashReportSummary),
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary)
      ),
      switchMap(([, crashReportSummary, needsReviewCrashReportSummary]) => {
        let crashNumber: string;
        switch (true) {
          case CommonUtils.isDefined(crashReportSummary): {
            crashNumber = crashReportSummary.oss_id;
            break;
          }
          case CommonUtils.isDefined(needsReviewCrashReportSummary): {
            crashNumber = needsReviewCrashReportSummary.oss_id;
            break;
          }
          default: {
            throw new TechnicalException('crash report summary and needs review crash report summary cannot both be null');
          }
        }
        if (CommonUtils.isNullOrUndefined(crashNumber)) {
          throw new TechnicalException('crash number cannot be null');
        }
        const loaderId = this.uuidService.generate();
        this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Validating eCrash report...', loaderId }));
        return this.eCrashService.validateReport(crashNumber).pipe(
          map(validationObject => ECrashActions.validationObjectLoaded({ validationObject })),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
        );
      })
    );
  });

  crashReportApproved$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportApprovalActions.crashReportApproved),
      withLatestFrom(
        this.store.select(AuthSelectors.selectUserIsReviewer),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(CrashReportSelectors.selectSupervisorCrashReportSummary)
      ),
      // if user is a supervisor and the environment is development, the effect will be handled by the NavigationEffects class
      // when the reviewer workflow is deployed to production, we just need to check the user's role
      filter(([, , isSupervisor]) => {
        if (isSupervisor && this.configService.getIsDevelopment()) {
          this.store.dispatch(NavigationActions.navigateFromReviewFormToReviewRejectedApprovedList());
          return false;
        }
        return true;
      }),
      switchMap(([, isReviewer, isSupervisor, needsReviewCrashReportSummary]) => {
        switch (true) {
          case isReviewer && this.configService.getIsProduction(): {
            throw new UnsupportedOperationException('reviewer workflow has not been deployed to production');
          }
          case isReviewer && this.configService.getIsDevelopment():
          case isSupervisor && this.configService.getIsProduction(): {
            const loaderId = this.uuidService.generate();
            this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Submitting eCrash report...', loaderId }));
            return this.eCrashService.submitReport(needsReviewCrashReportSummary.oss_id).pipe(
              map(() => ECrashActions.crashReportSubmitted()),
              catchError(error => this.serverErrorService.handleError(error)),
              finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
            );
          }
          default: {
            throw new UnsupportedOperationException('invalid combination of user role and environment');
          }
        }
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly serverErrorService: ServerErrorService,
    private readonly eCrashService: ECrashService,
    private readonly uuidService: UuidService,
    private readonly configService: ConfigService
  ) {}
}
