<div
  class="custom-snackbar-container"
  [ngClass]="{
    'custom-snackbar-container-info': data.level === 'info',
    'custom-snackbar-container-success': data.level === 'success',
    'custom-snackbar-container-error': data.level === 'error'
  }"
>
  <div class="custom-snackbar-content">
    <div
      class="custom-snackbar-icon-container"
      [ngClass]="{
        'custom-snackbar-icon-container-info': data.level === 'info',
        'custom-snackbar-icon-container-success': data.level === 'success',
        'custom-snackbar-icon-container-error': data.level === 'error'
      }"
    >
      <mat-icon class="custom-snackbar-icon custom-snackbar-icon-info" *ngIf="data.level === 'info'"> info </mat-icon>
      <mat-icon class="custom-snackbar-icon custom-snackbar-icon-success" *ngIf="data.level === 'success'"> check_circle </mat-icon>
      <mat-icon class="custom-snackbar-icon custom-snackbar-icon-error" *ngIf="data.level === 'error'"> warning </mat-icon>
    </div>
    <div class="custom-snackbar-message">{{ data.message }}</div>
  </div>

  <div class="custom-snackbar-progress-bar">
    <div
      class="custom-snackbar-numerator"
      [ngClass]="{
        'custom-snackbar-numerator-info': data.level === 'info',
        'custom-snackbar-numerator-success': data.level === 'success',
        'custom-snackbar-numerator-error': data.level === 'error'
      }"
      [ngStyle]="{ width: numerator }"
    ></div>
    <div class="custom-snackbar-denominator" [ngStyle]="{ width: denominator }"></div>
  </div>
</div>
