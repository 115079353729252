import { createReducer, on } from '@ngrx/store';
import { UnitActions } from '../actions';
import { UnitState } from '../state/unit.state';

export const initialUnitState: UnitState = { units: [], unit: null, assignmentId: null };

export const unitReducer = createReducer(
  initialUnitState,
  on(UnitActions.unitsLoaded, (state, action): UnitState => ({ ...state, units: action.units })),
  on(UnitActions.unitAssigned, (state, action): UnitState => ({ ...state, unit: action.unit, assignmentId: action.assignmentId })),
  on(UnitActions.resetUnitState, (state): UnitState => ({ ...state, ...initialUnitState }))
);
