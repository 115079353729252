import { ErrorHandler, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import { AppState } from '../../store/state/app.state';
import { ClientCurrentUser } from '../auth/data-model/models/client-current-user.model';
import { AuthSelectors } from '../auth/store/selectors';

@Injectable({
  providedIn: 'root',
})
export class SentryErrorHandler implements ErrorHandler {
  constructor(private readonly store: Store<AppState>) {
    this.store.select(AuthSelectors.selectUser);
  }

  setUser(user: ClientCurrentUser | undefined) {
    Sentry.setUser(user ? { username: user.username } : null);
  }

  handleError(error: any): void {
    console.error(error);
    if (error) {
      if (error.originalError) {
        Sentry.captureException(error.originalError);
      } else if (error.error) {
        Sentry.captureException(error.error);
      } else if (error.message) {
        Sentry.captureException(error.message);
      } else if (typeof error === 'string') {
        Sentry.captureException(new Error(error));
      } else {
        Sentry.captureException(error);
      }
    }

    throw error;
  }
}
