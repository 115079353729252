{
  "name": "oss-webapp-v2",
  "version": "0.0.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve --port 3000",
    "build": "ng build",
    "build:prod": "ng build --configuration production",
    "build:dev": "ng build --configuration development",
    "sentry:sourcemaps": "sh sentrySourcemaps.sh",
    "sentry:sourcemaps:prod": "sh sentrySourcemaps.sh --prod",
    "watch": "ng build --watch --configuration development",
    "test": "ng test",
    "lint": "ng lint"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^17.1.0",
    "@angular/cdk": "^17.1.2",
    "@angular/common": "^17.1.0",
    "@angular/compiler": "^17.1.0",
    "@angular/core": "^17.1.0",
    "@angular/forms": "^17.1.0",
    "@angular/google-maps": "^18.1.0",
    "@angular/material": "^17.1.2",
    "@angular/material-moment-adapter": "^17.2.1",
    "@angular/platform-browser": "^17.1.0",
    "@angular/platform-browser-dynamic": "^17.1.0",
    "@angular/router": "^17.1.0",
    "@angular/service-worker": "^17.1.0",
    "@googlemaps/js-api-loader": "^1.16.6",
    "@ngrx/effects": "^17.1.0",
    "@ngrx/entity": "^17.1.0",
    "@ngrx/store": "^17.1.0",
    "@ngrx/store-devtools": "^17.1.0",
    "html2canvas": "^1.4.1",
    "jspdf": "^2.5.1",
    "jspdf-autotable": "^3.8.2",
    "maplibre-gl": "^4.5.0",
    "moment": "^2.30.1",
    "posthog-js": "^1.148.1",
    "radar-sdk-js": "^4.3.0",
    "rxjs": "~7.8.0",
    "smartystreets-javascript-sdk": "^5.1.1",
    "tslib": "^2.3.0",
    "uuid": "^11.0.2",
    "zone.js": "~0.14.3"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^17.1.3",
    "@angular-eslint/builder": "17.2.1",
    "@angular-eslint/eslint-plugin": "17.2.1",
    "@angular-eslint/eslint-plugin-template": "17.2.1",
    "@angular-eslint/schematics": "17.2.1",
    "@angular-eslint/template-parser": "17.2.1",
    "@angular/cli": "^17.1.3",
    "@angular/compiler-cli": "^17.1.0",
    "@ngrx/eslint-plugin": "^17.1.0",
    "@sentry/angular-ivy": "^7.112.2",
    "@sentry/cli": "^2.28.0",
    "@types/google.maps": "^3.55.11",
    "@types/jasmine": "~5.1.0",
    "@typescript-eslint/eslint-plugin": "6.19.0",
    "@typescript-eslint/parser": "6.19.0",
    "eslint": "^8.56.0",
    "jasmine-core": "~5.1.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "prettier": "^3.2.5",
    "typescript": "~5.3.2"
  }
}
