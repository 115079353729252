import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { MAT_SNACK_BAR_DATA } from '@angular/material/snack-bar';
import { Subscription, interval } from 'rxjs';
import { SnackbarLevel } from '../../../modules/snackbar/data-model/types/snackbar-level.type';
import { OssOptional } from '../../../utils/oss-optional';

@Component({
  selector: 'app-custom-snackbar',
  standalone: true,
  imports: [MatIconModule, CommonModule],
  templateUrl: './custom-snackbar.component.html',
  styleUrls: ['./custom-snackbar.component.scss'],
})
export class CustomSnackbarComponent implements OnInit, OnDestroy {
  numerator: string = '0%';
  denominator: string = '100%';
  private subscription: Subscription;

  constructor(@Inject(MAT_SNACK_BAR_DATA) public data: { message: string; level: SnackbarLevel; duration: number }) {}

  ngOnInit(): void {
    const ms = 20;

    this.subscription = interval(ms).subscribe(i => {
      const percentageComplete = ((i * ms) / this.data.duration) * 100;

      this.numerator = `${percentageComplete}%`;
      this.denominator = `${100 - percentageComplete}%`;

      if (percentageComplete >= 100) {
        this.subscription.unsubscribe();
      }
    });
  }

  ngOnDestroy(): void {
    OssOptional.ofNullable(this.subscription).ifPresent(subscription => subscription.unsubscribe());
  }
}
