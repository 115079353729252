import { createReducer, on } from '@ngrx/store';
import { NavigationActions } from '../actions';
import { NavigationState } from '../state/navigation.state';

export const initialNavigationState: NavigationState = {
  // review form -> non-review form -> review form
  isRedirectFromNonReview: false,
  isRedirectFromReview: false,

  // activity list -> non-review-form -> activity list
  isRedirectFromNonActivity: false,
  isRedirectFromActvity: false,
};

export const navigationReducer = createReducer(
  initialNavigationState,

  // review form -> non-review form -> review form
  on(NavigationActions.setIsRedirectFromReview, (state, action): NavigationState => ({ ...state, isRedirectFromReview: action.isRedirectFromReview })),
  on(NavigationActions.setIsRedirectFromNonReview, (state, action): NavigationState => ({ ...state, isRedirectFromNonReview: action.isRedirectFromNonReview })),

  // activity list -> non-review-form -> activity list
  on(NavigationActions.setIsRedirectFromActvity, (state, action): NavigationState => ({ ...state, isRedirectFromActvity: action.isRedirectFromActivity })),
  on(
    NavigationActions.setIsRedirectFromNonActivity,
    (state, action): NavigationState => ({ ...state, isRedirectFromNonActivity: action.isRedirectFromNonActivity })
  ),

  // reset navigation state
  on(NavigationActions.resetNavigationState, (state): NavigationState => ({ ...state, ...initialNavigationState }))
);
