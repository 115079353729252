import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, catchError, map, of, switchMap } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { AuthService } from '../../services/api/auth/auth.service';
import { AuthSelectors } from '../../store/selectors';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly localStorageService: LocalStorageService
  ) {}

  canActivate(_route: ActivatedRouteSnapshot, _state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.store.select(AuthSelectors.selectUser).pipe(
      map(user => CommonUtils.isDefined(user)),
      switchMap(userIsSignedIn => {
        if (!userIsSignedIn) {
          return of(this.clearUser());
        }

        return this.authService.checkToken().pipe(
          map(response => {
            if (!response.success) {
              return this.clearUser();
            }
            return true;
          }),
          catchError(() => of(this.clearUser()))
        );
      })
    );
  }

  canActivateChild(childRoute: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.canActivate(childRoute, state);
  }

  private clearUser(): UrlTree {
    this.localStorageService.clearApiToken();
    this.localStorageService.clearUser();
    return this.router.parseUrl('/auth/sign-in');
  }
}
