import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, first, switchMap } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { HttpMethod } from '../../data-model/types/http-method.type';
import { CommonUtils } from '../../utils/common-utils';

@Injectable({ providedIn: 'root' })
export class ApiGatewayService {
  private readonly OSS_API_TOKEN_KEY = 'oss-api-token';

  constructor(
    private readonly http: HttpClient,
    private readonly store: Store<AppState>
  ) {}

  get<T>(
    url: string,
    params: { [key: string]: unknown } | null = {},
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } | null = {},
    urlSearchParams: URLSearchParams | null = null,
    apiVersion: string = '1.0.0'
  ): Observable<T> {
    return this.request('GET', url, params, null, appendApiVersion, additionalHeaders, urlSearchParams, apiVersion);
  }

  post<T>(
    url: string,
    body: unknown,
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } = {},
    apiVersion: string = '1.0.0'
  ): Observable<T> {
    return this.request('POST', url, null, body, appendApiVersion, additionalHeaders, null, apiVersion);
  }

  put<T>(
    url: string,
    body: unknown,
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } = {},
    apiVersion: string = '1.0.0'
  ): Observable<T> {
    return this.request('PUT', url, null, body, appendApiVersion, additionalHeaders, null, apiVersion);
  }

  patch<T>(
    url: string,
    body: unknown,
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } = {},
    apiVersion: string = '1.0.0'
  ): Observable<T> {
    return this.request('PATCH', url, null, body, appendApiVersion, additionalHeaders, null, apiVersion);
  }

  delete<T>(
    url: string,
    params: unknown = {},
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } = {},
    apiVersion: string = '1.0.0'
  ): Observable<T> {
    return this.request('DELETE', url, params, null, appendApiVersion, additionalHeaders, null, apiVersion);
  }

  private request<T>(
    httpMethod: HttpMethod,
    url: string,
    params: unknown,
    body: unknown,
    appendApiVersion: boolean = true,
    additionalHeaders: { [key: string]: string } | null,
    urlSearchParams: URLSearchParams | null = null,
    apiVersion: string
  ): Observable<T> {
    return this.store.select(AuthSelectors.selectApiToken).pipe(
      first(),
      switchMap(apiToken => {
        const { apiUrl } = environment.appConfig;
        let requestUrl = appendApiVersion ? `${apiUrl}/${url}/${apiVersion}` : `${apiUrl}/${url}`;
        if (CommonUtils.isDefined(urlSearchParams)) {
          requestUrl += `?${urlSearchParams}`;
        }
        return this.http.request<T>(httpMethod, requestUrl, {
          body,
          headers: this.buildHttpHeaders(apiToken, additionalHeaders),
          params: this.buildHttpParams(params),
          responseType: 'json',
        });
      })
    );
  }

  private buildHttpParams(params: unknown): HttpParams {
    let httpParams = new HttpParams();
    if (CommonUtils.hasContent(params)) {
      Object.entries(params).forEach(([key, value]) => (httpParams = httpParams.append(key, value.toString())));
    }
    return httpParams;
  }

  private buildHttpHeaders(apiToken: string, additionalHeaders: { [key: string]: string } | null): HttpHeaders {
    let httpHeaders = new HttpHeaders();
    if (CommonUtils.isDefined(apiToken)) {
      httpHeaders = httpHeaders.append(this.OSS_API_TOKEN_KEY, apiToken);
    }
    if (CommonUtils.hasContent(additionalHeaders)) {
      Object.entries(additionalHeaders).forEach(([key, value]) => (httpHeaders = httpHeaders.append(key, value)));
    }
    return httpHeaders;
  }
}
