import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { DateUtils } from '../../../../shared/utils/date-utils';
import { OssOptional } from '../../../../shared/utils/oss-optional';
import { ActivityRecordVerificationStatus } from '../../../data-model/enums/activity-record-verification-status.enum';
import { CloseEventRequest } from '../../../data-model/models/close-event-request.model';
import { OssActivityRecord } from '../../../data-model/models/oss-activity-record.model';
import { P1ActivityRecord } from '../../../data-model/models/p1-activity-record.model';

interface VerifiedOrUnverifiedActivityResponse {
  total_results: string;
  limit: number;
  offset: number;
  results: OssActivityRecord[];
  total_missing: number;
}

interface MissingActivityResponse {
  total_results: number;
  results: P1ActivityRecord[];
  offset: number;
  limit: number;
}

interface GetActivityResponse {
  total_results: number;
  results: OssActivityRecord[];
  offset: number;
  limit: number;
}

@Injectable({ providedIn: 'root' })
export class ActivityService {
  constructor(private readonly apiGateway: ApiGatewayService) {}

  getActivityRecords(
    limit: number,
    offset: number,
    verificationStatus: ActivityRecordVerificationStatus,
    serviceLocationId: number,
    isSupervisor: boolean,
    isAdministrator: boolean
  ): Observable<GetActivityResponse> {
    const params: Partial<{
      limit: number;
      offset: number;
      verification_status: 'verified' | 'unverified';
      service_location_id: number;
    }> = {};

    OssOptional.of(limit).ifPresent(value => (params.limit = value));
    OssOptional.of(offset).ifPresent(value => (params.offset = value));

    switch (verificationStatus) {
      case ActivityRecordVerificationStatus.VERIFIED:
      case ActivityRecordVerificationStatus.UNVERIFIED: {
        params.verification_status = verificationStatus === ActivityRecordVerificationStatus.VERIFIED ? 'verified' : 'unverified';
        let activity$: Observable<VerifiedOrUnverifiedActivityResponse>;
        if (!isSupervisor && !isAdministrator) {
          activity$ = this.apiGateway.get<VerifiedOrUnverifiedActivityResponse>('oss/activity/agent', params);
        } else {
          OssOptional.of(serviceLocationId).ifPresent(value => (params.service_location_id = value));
          activity$ = this.apiGateway.get<VerifiedOrUnverifiedActivityResponse>('oss/activity/location', params);
        }
        return activity$.pipe(map(response => this.mapVerifiedOrUnverifiedActivityResponseToGetActivityResponse(response, verificationStatus)));
      }

      case ActivityRecordVerificationStatus.MISSING: {
        return this.apiGateway
          .get<MissingActivityResponse>('oss/activity/missing', params, true, null, null, '1.0.0')
          .pipe(map(response => this.mapMissingActivityResponseToGetActivityResponse(response)));
      }

      default: {
        throw new TechnicalException(`Unknown verification status: ${verificationStatus}`);
      }
    }
  }

  patchActivityRecordItemNumber(activityRecordId: number, itemNumber: string): Observable<ApiOperationStatus> {
    return this.apiGateway.patch<ApiOperationStatus>(`oss/activity/index`, { activity_id: activityRecordId, item_num: itemNumber });
  }

  closeItem(request: CloseEventRequest): Observable<ApiOperationStatus> {
    return this.apiGateway.post('oss/activity/index', request);
  }

  private mapP1ActivityRecordToOssActivityRecord(p1ActivityRecord: P1ActivityRecord): OssActivityRecord {
    return {
      crash_num: null, // db field
      created_at: new Date(p1ActivityRecord.incident_datetime), // used to aggregate activity records
      deleted_at: null, // db field
      disposition: p1ActivityRecord.disposition,
      has_report: p1ActivityRecord.has_report,
      id: null, // db field
      intersecting_road: p1ActivityRecord.cross_street,
      is_missing: true, // missing records are missing
      item_num: p1ActivityRecord.item_num,
      location: null, // latitude and longitude, not provided by P1
      modified_at: null, // db field
      notes: null, // column in activity table, not provided by P1
      p1_close_datetime: p1ActivityRecord.close_datetime,
      p1_create_datetime: p1ActivityRecord.create_datetime,
      p1_cross_street: p1ActivityRecord.cross_street,
      p1_enroute_datetime: p1ActivityRecord.enroute_datetime,
      p1_incident_datetime: p1ActivityRecord.incident_datetime,
      p1_onscene_datetime: p1ActivityRecord.onscene_datetime,
      primary_road: null, // not provided by P1
      service_location_id: 1, // p1 is only used by the new orleans service location
      time_closed: DateUtils.mapP1DateTimeToIsoString(p1ActivityRecord.close_datetime), // used in time on call pipe
      time_dispatched: DateUtils.mapP1DateTimeToIsoString(p1ActivityRecord.enroute_datetime), // used in time on call pipe
      user_id: p1ActivityRecord.username,
      verification_status: 'missing', // missing records are missing
      verified_at: null, // missing records are not verified
      verified_by: null, // missing records are not verified
    };
  }

  private mapVerifiedOssActivityRecord(ossActivityRecord: OssActivityRecord): OssActivityRecord {
    const getDateOrElseNull = (dateString: string) =>
      OssOptional.ofNullable(dateString)
        .map(dateString => new Date(dateString))
        .orElseNull();

    return {
      ...ossActivityRecord,
      created_at: getDateOrElseNull(ossActivityRecord.p1_incident_datetime),
      time_closed: getDateOrElseNull(ossActivityRecord.p1_close_datetime),
      time_dispatched: getDateOrElseNull(ossActivityRecord.p1_enroute_datetime),
    };
  }

  private mapMissingActivityResponseToGetActivityResponse(response: MissingActivityResponse): GetActivityResponse {
    return {
      total_results: response.total_results,
      results: response.results.map(result => this.mapP1ActivityRecordToOssActivityRecord(result)),
      offset: response.offset,
      limit: response.limit,
    };
  }

  private mapVerifiedOrUnverifiedActivityResponseToGetActivityResponse(
    response: VerifiedOrUnverifiedActivityResponse,
    verificationStatus: ActivityRecordVerificationStatus
  ): GetActivityResponse {
    return {
      total_results: parseInt(response.total_results, 10),
      results:
        ActivityRecordVerificationStatus.VERIFIED === verificationStatus
          ? response.results.map(result => this.mapVerifiedOssActivityRecord(result))
          : response.results,
      offset: response.offset,
      limit: response.limit,
    };
  }
}
