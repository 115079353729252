import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { AuthSelectors } from '../../../auth/store/selectors';
import { SnackbarService } from '../../../shared/modules/snackbar/services/snackbar/snackbar.service';
import { PosthogService } from '../../../shared/services/posthog/posthog.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UuidService } from '../../../shared/services/uuid/uuid.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CollectionUtils } from '../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { ActivityTableRevisionModalComponent } from '../../components/activity/activity-table-revision-modal/activity-table-revision-modal.component';
import { ActivityRecordVerificationStatus } from '../../data-model/enums/activity-record-verification-status.enum';
import { ActivityService } from '../../services/api/activity/activity.service';
import { ActivityActions, CrashReportActions } from '../actions';
import { ActivitySelectors, CrashReportSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class ActivityEffects {
  loadActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadActivityRecords, ActivityActions.setLimit, ActivityActions.setOffset, ActivityActions.setVerificationStatus),
      withLatestFrom(
        this.store.select(ActivitySelectors.selectLimit),
        this.store.select(ActivitySelectors.selectOffset),
        this.store.select(ActivitySelectors.selectVerificationStatus),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, limit, offset, verificationStatus, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService.getActivityRecords(limit, offset, verificationStatus, serviceLocationId, userIsSupervisor, userIsAdministrator).pipe(
          map(response => ActivityActions.activityRecordsLoaded({ activityRecords: response.results, numberOfActivityRecords: response.total_results })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  loadUnverifiedActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadUnverifiedActivityRecords),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService
          .getActivityRecords(null, null, ActivityRecordVerificationStatus.UNVERIFIED, serviceLocationId, userIsSupervisor, userIsAdministrator)
          .pipe(
            map(response => ActivityActions.unverifiedActivityRecordsLoaded({ numberOfUnverifiedActivityRecords: response.total_results })),
            catchError(error => this.serverErrorService.handleError(error))
          )
      )
    );
  });

  loadMissingActivityRecords$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.loadMissingActivityRecords),
      withLatestFrom(
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([, serviceLocationId, userIsSupervisor, userIsAdministrator]) =>
        this.activityService
          .getActivityRecords(null, null, ActivityRecordVerificationStatus.MISSING, serviceLocationId, userIsSupervisor, userIsAdministrator)
          .pipe(
            map(response => ActivityActions.missingActivityRecordsLoaded({ numberOfMissingActivityRecords: response.total_results })),
            catchError(error => this.serverErrorService.handleError(error))
          )
      )
    );
  });

  openRevisionModal$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.openRevisionModal),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([action, serviceLocationId]) => {
        const data = { activityRecord: action.activityRecord, isInitialVerification: action.isInitialVerification, serviceLocationId };
        return this.dialog
          .open(ActivityTableRevisionModalComponent, { data })
          .afterClosed()
          .pipe(
            map(result => {
              if (CommonUtils.isDefined(result) && CommonUtils.isDefinedAndTrue(result.success)) {
                return ActivityActions.updateItemNumber({ activityRecordId: action.activityRecord.id, itemNumber: result.itemNumber });
              }
              return ActivityActions.loadActivityRecords();
            })
          );
      })
    );
  });

  updateItemNumber$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.updateItemNumber),
      switchMap(action =>
        this.activityService.patchActivityRecordItemNumber(action.activityRecordId, action.itemNumber).pipe(
          filter(response => {
            if (CommonUtils.isNullOrUndefined(response) || CommonUtils.isDefinedAndFalse(response.success)) {
              this.snackbarService.showSnackbar('Failed to update item number', 'error');
              return false;
            }
            return true;
          }),
          map(() => ActivityActions.itemNumberUpdated({ itemNumber: action.itemNumber })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  itemNumberUpdated$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.itemNumberUpdated),
      withLatestFrom(
        this.store.select(ActivitySelectors.selectLimit),
        this.store.select(ActivitySelectors.selectOffset),
        this.store.select(ServiceLocationSelectors.selectServiceLocationId),
        this.store.select(AuthSelectors.selectUserIsSupervisor),
        this.store.select(AuthSelectors.selectUserIsAdministrator)
      ),
      switchMap(([action, limit, offset, serviceLocationId, userIsSupervisor, userIsAdministrator]) => {
        return this.activityService
          .getActivityRecords(
            limit + 10,
            Math.max(offset - 5, 0),
            ActivityRecordVerificationStatus.UNVERIFIED,
            serviceLocationId,
            userIsSupervisor,
            userIsAdministrator
          )
          .pipe(
            map(response => {
              const updatedActivityRecord = response.results.find(record => record.item_num === action.itemNumber);
              if (CommonUtils.isNullOrUndefined(updatedActivityRecord)) {
                this.snackbarService.showSnackbar('The item number has been successfully verified', 'success');
                this.store.dispatch(ActivityActions.loadUnverifiedActivityRecords());
                this.store.dispatch(ActivityActions.loadMissingActivityRecords());
                return ActivityActions.loadActivityRecords();
              }
              return ActivityActions.openRevisionModal({ activityRecord: updatedActivityRecord, isInitialVerification: false });
            }),
            catchError(error => this.serverErrorService.handleError(error))
          );
      })
    );
  });

  closeItem$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ActivityActions.closeItem),
      switchMap(action => {
        return this.activityService.closeItem(action.request).pipe(
          withLatestFrom(
            this.store.select(CrashReportSelectors.selectCrashReportSummary),
            this.store.select(ServiceLocationSelectors.selectServiceLocationId),
            this.store.select(AuthSelectors.selectUser)
          ),
          tap(([, crashReportSummary, serviceLocationId, user]) => {
            if (action.request.disposition !== 'RTF') {
              this.posthogService.captureNonReportItemCreatedEvent(
                OssOptional.ofNullable(crashReportSummary)
                  .map(summary => summary.oss_id)
                  .orElse('oss id not available'),
                OssOptional.ofNullable(user)
                  .map(currentUser => currentUser.username)
                  .orElse('username not available'),
                serviceLocationId,
                action.request.disposition
              );
            }
          }),
          map(() => ActivityActions.itemClosed()),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  itemClosed$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(ActivityActions.itemClosed),
        withLatestFrom(
          this.store.select(CrashReportSelectors.selectIsRedirectFromNonActivity),
          this.store.select(ServiceLocationSelectors.selectServiceLocationId),
          this.store.select(AuthSelectors.selectUserIsSupervisor),
          this.store.select(AuthSelectors.selectUserIsAdministrator)
        ),
        filter(([, isRedirectFromNonActivity]) => isRedirectFromNonActivity),
        switchMap(([, , serviceLocationId, userIsSupervisor, userIsAdministrator]) => {
          const loaderId = this.uuidService.generate();
          this.store.dispatch(LoadingActions.showLoadingIndicatorWithId({ message: 'Loading activity records...', loaderId }));
          return this.activityService
            .getActivityRecords(1, 0, ActivityRecordVerificationStatus.UNVERIFIED, serviceLocationId, userIsSupervisor, userIsAdministrator)
            .pipe(
              switchMap(response => {
                const result = CollectionUtils.getFirstElement(response.results);
                return this.activityService.patchActivityRecordItemNumber(result.id, result.item_num).pipe(
                  tap(() => {
                    this.store.dispatch(CrashReportActions.crashReportSummarySelected({ crashReportSummary: null }));
                    this.store.dispatch(CrashReportActions.crashReportDetailLoaded({ crashReportDetail: null }));
                    this.router.navigate(['/crash-report/activity']);
                  }),
                  catchError(error => this.serverErrorService.handleError(error)),
                  finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicatorWithId({ loaderId })))
                );
              }),
              catchError(error => this.serverErrorService.handleError(error))
            );
        })
      );
    },
    { dispatch: false }
  );

  constructor(
    private readonly actions$: Actions,
    private readonly serverErrorService: ServerErrorService,
    private readonly activityService: ActivityService,
    private readonly store: Store<AppState>,
    private readonly dialog: MatDialog,
    private readonly snackbarService: SnackbarService,
    private readonly posthogService: PosthogService,
    private readonly router: Router,
    private readonly uuidService: UuidService
  ) {}
}
