import { createReducer, on } from '@ngrx/store';
import { SchemaActions } from '../actions';
import { SchemaState } from '../state/schema.state';

export const initialSchemaState: SchemaState = {
  defaultSchema: null,
  defaultSchemaVersion: null,
  schema: null,
  schemaVersion: null,
};

export const schemaReducer = createReducer(
  initialSchemaState,
  on(SchemaActions.schemaLoaded, (state, action): SchemaState => ({ ...state, schema: action.schema, schemaVersion: action.version })),
  on(SchemaActions.defaultSchemaLoaded, (state, action): SchemaState => ({ ...state, defaultSchema: action.schema, defaultSchemaVersion: action.version }))
);
