import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { TechnicalException } from '../../../../shared/data-model/models/technical-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { SupervisorCrashReportSummary } from '../../../data-model/models/supevisor-crash-report-summary.model';

@Injectable({ providedIn: 'root' })
export class SupervisorService {
  private readonly BASE_URL = 'oss/supervisor';

  constructor(private readonly apiGateway: ApiGatewayService) {}

  getNeedsReviewCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<SupervisorCrashReportSummary[]> {
    return this.getCrashReportSummaries(['agent_qc_check'], serviceLocationId, limit, offset);
  }

  getRejectedCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<SupervisorCrashReportSummary[]> {
    return this.getCrashReportSummaries(['incomplete'], serviceLocationId, limit, offset);
  }

  getApprovedCrashReportSummaries(serviceLocationId: number, limit: number = 1000, offset: number = 0): Observable<SupervisorCrashReportSummary[]> {
    return this.getCrashReportSummaries(['secondary_qc_check', 'secondary_incomplete', 'submitted'], serviceLocationId, limit, offset);
  }

  approveCrashReport(crashReportNumber: string): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, { crash_num: crashReportNumber, is_accepted: true });
  }

  rejectCrashReport(crashReportNumber: string, notes: string): Observable<ApiOperationStatus> {
    return this.apiGateway.post(`${this.BASE_URL}/review`, { crash_num: crashReportNumber, is_accepted: false, notes: notes, status_change_notes: notes });
  }

  private getCrashReportSummaries(statuses: string[], serviceLocationId: number, limit: number, offset: number): Observable<SupervisorCrashReportSummary[]> {
    if (CommonUtils.isEmpty(statuses)) {
      throw new TechnicalException('must provide statuses to get supervisor crash report summaries');
    }
    const additionalHeaders = { ETag: StringUtils.EMPTY };
    return this.apiGateway.get(
      `${this.BASE_URL}/reports`,
      null,
      true,
      additionalHeaders,
      this.buildUrlSearchParams(serviceLocationId, limit, offset, statuses)
    );
  }

  private buildUrlSearchParams(serviceLocationId: number, limit: number, offset: number, statuses: string[]): URLSearchParams {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.append('service_location_id', serviceLocationId.toString());
    urlSearchParams.append('limit', limit.toString());
    urlSearchParams.append('offset', offset.toString());
    statuses.forEach(status => urlSearchParams.append('status', status));
    return urlSearchParams;
  }
}
