import { Injectable } from '@angular/core';
import { FormArray, FormGroup } from '@angular/forms';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, concatMap, finalize, map, switchMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { TechnicalException } from '../../../shared/data-model/models/technical-exception.model';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { LoadingActions } from '../../../shared/store/actions';
import { CollectionUtils } from '../../../shared/utils/collection-utils';
import { CommonUtils } from '../../../shared/utils/common-utils';
import { ObjectUtils } from '../../../shared/utils/object-utils';
import { OssOptional } from '../../../shared/utils/oss-optional';
import { FormConstants } from '../../constants/form.constants';
import { AddOrRemoveGroupRequest } from '../../data-model/models/add-or-remove-group-request.model';
import { CrashReportSummary } from '../../data-model/models/crash-report-summary.model';
import { SchemaGroupMetaData } from '../../data-model/models/schema-group-meta-data.model';
import { SubGroupMetaData } from '../../data-model/models/sub-group-meta-data.model';
import { CrashReportService } from '../../services/api/crash-report/crash-report.service';
import { CrashReportDetailFormService } from '../../services/form/crash-report-detail-form/crash-report-detail-form.service';
import { CrashReportStructuralActions } from '../actions';
import { CrashReportSelectors, RuleSetSelectors, SchemaSelectors, ServiceLocationSelectors } from '../selectors';

@Injectable()
export class CrashReportStructuralEffects {
  addSchemaGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStructuralActions.addSchemaGroup),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        // save a reference to the schema group form array
        const schemaGroupFormArray = this.formService.form.controls[action.schemaGroupMetaData.key] as FormArray;

        // the new schema group index is the number of schema groups
        const newSchemaGroupIndex = schemaGroupFormArray.length;

        // the new schema group api index is the number of schema groups that are not deleted
        const newSchemaGroupApiIndex = CollectionUtils.count(
          schemaGroupFormArray.controls,
          (schemaGroup: FormGroup) => !schemaGroup.value[FormConstants.META_DATA][FormConstants.IS_DELETED]
        );

        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Adding group...' }));

        return this.crashReportService
          .addSchemaGroup(
            this.buildAddOrRemoveGroupRequest(action.schemaGroupMetaData, null, crashReportSummary, true),
            action.schemaGroupMetaData,
            newSchemaGroupIndex,
            newSchemaGroupApiIndex
          )
          .pipe(
            withLatestFrom(this.store.select(SchemaSelectors.selectSchema), this.store.select(RuleSetSelectors.selectRuleSet)),
            tap(([response, schema, ruleSet]) => this.formService.addSchemaGroup(action.schemaGroupMetaData, schema, response, ruleSet)),
            map(() => CrashReportStructuralActions.schemaGroupAdded()),
            catchError(error => this.serverErrorService.handleError(error)),
            finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
          );
      })
    );
  });

  removeSchemaGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStructuralActions.removeSchemaGroup),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        const request = this.buildAddOrRemoveGroupRequest(action.schemaGroupMetaData, null, crashReportSummary, false);
        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Removing group...' }));
        return this.crashReportService.removeSchemaGroup(request, action.schemaGroupMetaData).pipe(
          withLatestFrom(this.store.select(CrashReportSelectors.selectCrashReportDetail), this.store.select(SchemaSelectors.selectSchema)),
          tap(([response, crashReportDetail, schema]) =>
            // if a real BE call is made, the crash report detail will be returned in the response
            // if the service worker intercepts the call, the response object will be an object with a message key
            // todo: probably need to add same logic to remove sub group
            this.formService.removeSchemaGroup(
              action.schemaGroupMetaData,
              schema,
              // TODO: may have to do the same thing for add schema group, add sub group, and remove sub group
              this.responseIsFromServiceWorker(response) ? crashReportDetail : response,
              action.schemaGroupMetaData.index
            )
          ),
          map(() => CrashReportStructuralActions.schemaGroupRemoved()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
        );
      })
    );
  });

  addSubGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStructuralActions.addSubGroup),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        const { META_DATA, API_INDEX, SUB_GROUPS, DOM_ID, IS_DELETED } = FormConstants;

        // save a reference to the sub group form array
        const schemaGroupFormArray = this.formService.form.controls[action.schemaGroupMetaData.domId] as FormArray;
        const schemaGroupFormGroup = schemaGroupFormArray.controls.find(
          schemaGroup => schemaGroup.value[META_DATA][API_INDEX] === action.schemaGroupMetaData.apiIndex
        ) as FormGroup;
        const subGroupFormArray = schemaGroupFormGroup.controls[SUB_GROUPS] as FormArray;

        // build a list of sub groups that are scoped to the sub group dom id
        const subGroupsScopedToSubGroupDomId = subGroupFormArray.controls.filter(
          subGroup => subGroup.value[META_DATA][DOM_ID] === action.subGroupMetaData.domId
        );

        // the new sub group index is the number of sub groups that are scoped to the sub group dom id
        const newSubGroupIndex = subGroupsScopedToSubGroupDomId.length;

        // the new sub group api index is the number of sub groups that are scoped to the sub group dom id and are not deleted and is not a placeholder
        const newSubGroupApiIndex = subGroupsScopedToSubGroupDomId.filter(subGroup => !subGroup.value[META_DATA][IS_DELETED]).length;

        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Adding sub group...' }));

        return this.crashReportService
          .addSubGroup(
            this.buildAddOrRemoveGroupRequest(action.schemaGroupMetaData, action.subGroupMetaData, crashReportSummary, true),
            action.schemaGroupMetaData,
            action.subGroupMetaData,
            newSubGroupIndex,
            newSubGroupApiIndex
          )
          .pipe(
            withLatestFrom(this.store.select(SchemaSelectors.selectSchema), this.store.select(RuleSetSelectors.selectRuleSet)),
            tap(([response, schema, ruleSet]) => this.formService.addSubGroup(action.schemaGroupMetaData, action.subGroupMetaData, schema, response, ruleSet)),
            map(() => CrashReportStructuralActions.subGroupAdded()),
            catchError(error => this.serverErrorService.handleError(error)),
            finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
          );
      })
    );
  });

  removeSubGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStructuralActions.removeSubGroup),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      concatMap(([action, crashReportSummary]) => {
        const request = this.buildAddOrRemoveGroupRequest(action.schemaGroupMetaData, action.subGroupMetaData, crashReportSummary, false);
        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Removing sub group...' }));
        return this.crashReportService.removeSubGroup(request, action.schemaGroupMetaData, action.subGroupMetaData).pipe(
          withLatestFrom(this.store.select(SchemaSelectors.selectSchema)),
          tap(([response, schema]) =>
            this.formService.removeSubGroup(
              action.schemaGroupMetaData,
              action.subGroupMetaData,
              schema,
              response,
              action.subGroupMetaData.index,
              OssOptional.ofNullable(action.subGroupWasHiddenOnValueChange).orElse(false)
            )
          ),
          map(() => CrashReportStructuralActions.subGroupRemoved()),
          catchError(error => this.serverErrorService.handleError(error)),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
        );
      })
    );
  });

  swapVehicles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(CrashReportStructuralActions.swapVehicles),
      concatLatestFrom(() => this.store.select(CrashReportSelectors.selectCrashReportSummary)),
      switchMap(([action, crashReportSummary]) => {
        this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Swapping vehicles...' }));
        return this.crashReportService.swapVehicles(crashReportSummary.oss_id, action.fromIndex, action.tooIndex).pipe(
          withLatestFrom(this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
          tap(([, serviceLocationId]) => this.formService.swapVehicles(action.fromIndex, action.tooIndex, serviceLocationId)),
          map(() => CrashReportStructuralActions.vehiclesSwapped({ swapWasSuccessful: true })),
          catchError(error => {
            this.store.dispatch(CrashReportStructuralActions.vehiclesSwapped({ swapWasSuccessful: false }));
            return this.serverErrorService.handleError(error);
          }),
          finalize(() => this.store.dispatch(LoadingActions.hideLoadingIndicator()))
        );
      })
    );
  });

  private buildAddOrRemoveGroupRequest(
    schemaGroupMetaData: SchemaGroupMetaData,
    subGroupMetaData: SubGroupMetaData | null,
    crashReportSummary: CrashReportSummary,
    shouldAdd: boolean
  ): AddOrRemoveGroupRequest {
    const isSchemaGroupRequest = CommonUtils.isDefined(schemaGroupMetaData) && CommonUtils.isNullOrUndefined(subGroupMetaData);
    const isSubGroupRequest = CommonUtils.isDefined(schemaGroupMetaData) && CommonUtils.isDefined(subGroupMetaData);

    if (isSchemaGroupRequest && isSubGroupRequest) {
      throw new TechnicalException('schema group meta data and sub group meta data cannot both be defined');
    }
    if (!isSchemaGroupRequest && !isSubGroupRequest) {
      throw new TechnicalException('schema group meta data and sub group meta data cannot both be null');
    }

    const request: AddOrRemoveGroupRequest = {
      crash_num: crashReportSummary.oss_id,
      list_type: this.mapGroupKeyToListType(isSchemaGroupRequest ? schemaGroupMetaData : subGroupMetaData),
    };

    // if the schema group is being removed, the entity index is the schema group api index + 1
    if (isSchemaGroupRequest && !shouldAdd) {
      request.entity_index = schemaGroupMetaData.apiIndex + 1;
    }

    if (isSubGroupRequest) {
      // always set the group index to the schema group api index + 1 when adding or removing a sub group
      request.group_index = schemaGroupMetaData.apiIndex + 1;
      if (!shouldAdd) {
        // when removing a sub group, the entity index is the sub group api index + 1
        request.entity_index = subGroupMetaData.apiIndex + 1;
      }
    }

    return request;
  }

  private mapGroupKeyToListType(groupMetaData: SchemaGroupMetaData | SubGroupMetaData): string {
    return OssOptional.ofNullable(this.GROUP_KEY_LIST_TYPE_MAP.get(groupMetaData.key)).orElseThrow(
      () => new TechnicalException(`schema group key ${groupMetaData.key} does not have a corresponding list type`)
    );
  }

  private responseIsFromServiceWorker(response: unknown): boolean {
    CommonUtils.assert(CommonUtils.isDefined(response) && ObjectUtils.isObject(response), 'response must be a non-null object');
    return CommonUtils.isDefined(response['serviceWorkerMessage']);
  }

  private readonly GROUP_KEY_LIST_TYPE_MAP = new Map<string, string>([
    ['altgrid', 'grid'],
    ['narrsup', 'narrsup'],
    ['vehic', 'vehicle'],
    ['trailer', 'trailer'],
    ['occup', 'occupant'],
    ['pedes', 'pedestrian'],
    ['train', 'train'],
    ['trocc', 'train_occupant'],
    ['drwitness', 'driver_statement'],
    ['witness', 'witness_statement'],
  ]);

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly crashReportService: CrashReportService,
    private readonly serverErrorService: ServerErrorService,
    private readonly formService: CrashReportDetailFormService
  ) {}
}
