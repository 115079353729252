import { ActionReducerMap } from '@ngrx/store';
import { authReducer } from '../../modules/auth/store/reducers/auth.reducer';
import { AuthState } from '../../modules/auth/store/state/auth.state';
import { activityReducer } from '../../modules/crash-report/store/reducers/activity.reducer';
import { crashReportReducer } from '../../modules/crash-report/store/reducers/crash-report.reducer';
import { eCrashReducer } from '../../modules/crash-report/store/reducers/e-crash.reducer';
import { navigationReducer } from '../../modules/crash-report/store/reducers/navigation.reducer';
import { ruleSetReducer } from '../../modules/crash-report/store/reducers/rule-set.reducer';
import { schemaReducer } from '../../modules/crash-report/store/reducers/schema.reducer';
import { serviceLocationReducer } from '../../modules/crash-report/store/reducers/service-location.reducer';
import { unitReducer } from '../../modules/crash-report/store/reducers/unit.reducer';
import { ActivityState } from '../../modules/crash-report/store/state/activity.state';
import { CrashReportState } from '../../modules/crash-report/store/state/crash-report.state';
import { ECrashState } from '../../modules/crash-report/store/state/e-crash.state';
import { NavigationState } from '../../modules/crash-report/store/state/navigation.state';
import { RuleSetState } from '../../modules/crash-report/store/state/rule-set.state';
import { SchemaState } from '../../modules/crash-report/store/state/schema.state';
import { ServiceLocationState } from '../../modules/crash-report/store/state/service-location.state';
import { UnitState } from '../../modules/crash-report/store/state/unit.state';
import { loadingReducer } from '../../modules/shared/store/reducers/loading.reducer';
import { LoadingState } from '../../modules/shared/store/state/loading.state';

export interface AppState {
  activity: ActivityState;
  auth: AuthState;
  crashReport: CrashReportState;
  eCrash: ECrashState;
  loading: LoadingState;
  navigation: NavigationState;
  ruleSet: RuleSetState;
  schema: SchemaState;
  serviceLocation: ServiceLocationState;
  unit: UnitState;
}

export const reducers: ActionReducerMap<AppState> = {
  activity: activityReducer,
  auth: authReducer,
  crashReport: crashReportReducer,
  eCrash: eCrashReducer,
  loading: loadingReducer,
  navigation: navigationReducer,
  ruleSet: ruleSetReducer,
  schema: schemaReducer,
  serviceLocation: serviceLocationReducer,
  unit: unitReducer,
};
