import { createReducer, on } from '@ngrx/store';
import { RuleSetActions } from '../actions';
import { RuleSetState } from '../state/rule-set.state';

export const initialRuleSetState: RuleSetState = {
  defaultRuleSet: null,
  defaultRuleSetVersion: null,
  ruleSet: null,
  ruleSetVersion: null,
};

export const ruleSetReducer = createReducer(
  initialRuleSetState,
  on(RuleSetActions.ruleSetLoaded, (state, action): RuleSetState => ({ ...state, ruleSet: action.ruleSet, ruleSetVersion: action.version })),
  on(
    RuleSetActions.defaultRuleSetLoaded,
    (state, action): RuleSetState => ({ ...state, defaultRuleSet: action.ruleSet, defaultRuleSetVersion: action.version })
  )
);
