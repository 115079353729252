<div
  class="activity-revision-modal-container"
  [ngClass]="{
    'activity-revision-modal-container-initial-verification': data.isInitialVerification,
    'activity-revision-modal-container-non-initial-verification': !data.isInitialVerification
  }"
>
  <div class="activity-revision-modal-header">
    <div class="activity-revision-modal-close-icon-container">
      <mat-icon class="activity-revision-modal-close-icon" (click)="onClose()">close</mat-icon>
    </div>

    <div class="activity-revision-modal-warning-icon-container" *ngIf="!data.isInitialVerification">
      <mat-icon class="activity-revision-modal-warning-icon">warning</mat-icon>
    </div>

    <div class="activity-revision-modal-title">{{ data.isInitialVerification ? 'Revise Item Number' : 'Item Number Verification Failed' }}</div>
  </div>

  <div class="activity-revision-modal-content">
    <div class="activity-revision-modal-error-text" *ngIf="!data.isInitialVerification">
      Please check the dispatch software to confirm this item number. If you believe this verification failed incorrectly, please contact your supervisor.
    </div>

    <form [formGroup]="form">
      <app-oss-input formControlName="itemNumber" [showLabel]="false" [showSubscript]="false"></app-oss-input>
    </form>

    <div class="activity-revision-modal-help-text">Must be in format A-12345-67</div>
  </div>

  <div class="activity-revision-modal-actions">
    <app-primary-button
      color="blue"
      label="Reverify"
      (click)="onReverify()"
      [isDisabled]="form.invalid || form.controls['itemNumber'].value === data.activityRecord.item_num"
    ></app-primary-button>
  </div>
</div>
