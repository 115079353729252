import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { UnsupportedOperationException } from '../../../../shared/data-model/models/unsupported-operation-exception.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CommonUtils } from '../../../../shared/utils/common-utils';
import { StringUtils } from '../../../../shared/utils/string-utils';
import { AddCrashReportDetailRequest } from '../../../data-model/models/add-crash-report-detail-request.model';
import { AddOrRemoveGroupRequest } from '../../../data-model/models/add-or-remove-group-request.model';
import { CrashReportDetailBulkUpdateRequest } from '../../../data-model/models/crash-report-detail-bulk-update-request.model';
import { CrashReportDetailBulkUpdateResponse } from '../../../data-model/models/crash-report-detail-bulk-update-response.model';
import { CrashReportDetailUpdateRequest } from '../../../data-model/models/crash-report-detail-update-request.model';
import { CrashReportDetail } from '../../../data-model/models/crash-report-detail.model';
import { CrashReportSummaryFilter, getNulledOutCrashReportSummaryFilter } from '../../../data-model/models/crash-report-summary-filter.model';
import { CrashReportSummaryResponse } from '../../../data-model/models/crash-report-summary-response.model';
import { CrashReportSummaryListTab } from '../../../data-model/types/crash-report-summary-list-tab.type';

@Injectable({ providedIn: 'root' })
export class CrashReportService {
  private readonly NEW_ORLEANS_SERVICE_LOCATION_ID = 1;
  private readonly BALTIMORE_SERVICE_LOCATION_ID = 2;

  constructor(private readonly apiGateway: ApiGatewayService) {}

  getCrashReportSummaries(
    serviceLocationId: number,
    tab: Omit<CrashReportSummaryListTab, 'needsReview' | 'approved' | 'rejected'>,
    limit: number = 1000,
    offset: number = 0,
    filter: CrashReportSummaryFilter = getNulledOutCrashReportSummaryFilter()
  ): Observable<CrashReportSummaryResponse> {
    let statuses: string[];
    let hydrateReviews = false;
    let hydrateECrashSubmissionStatus = false;

    switch (tab) {
      case 'inProgress':
        statuses = ['open', 'in_progress'];
        break;

      case 'submitted':
        switch (serviceLocationId) {
          case this.NEW_ORLEANS_SERVICE_LOCATION_ID:
            statuses = ['agent_qc_check', 'secondary_qc_check'];
            break;
          case this.BALTIMORE_SERVICE_LOCATION_ID:
            statuses = ['submitted'];
            break;
          default:
            throw new UnsupportedOperationException(`Unsupported service location id: ${serviceLocationId}`);
        }
        break;

      case 'myApproved':
        statuses = ['ready', 'submitted'];
        hydrateECrashSubmissionStatus = true;
        break;

      case 'myRejected':
        statuses = ['incomplete', 'secondary_incomplete'];
        hydrateReviews = true;
        break;

      default:
        throw new UnsupportedOperationException(`Unsupported tab: ${tab}`);
    }

    const additionalHeaders = { ETag: StringUtils.EMPTY };

    return this.apiGateway.get(
      'oss/dashboard/reportsByStatus',
      null,
      true,
      additionalHeaders,
      this.buildUrlSearchParams(serviceLocationId, limit, offset, statuses, hydrateReviews, hydrateECrashSubmissionStatus, filter),
      '2.0.0'
    );
  }

  getCrashReportDetail(crash_num: string): Observable<CrashReportDetail> {
    return this.apiGateway.get('oss/crashReport/report', { crash_num });
  }

  updateCrashReportDetail(request: CrashReportDetailUpdateRequest): Observable<ApiOperationStatus> {
    return this.apiGateway.post('oss/crashReport/formField', request, true, null, '2.0.0');
  }

  bulkUpdateCrashReportDetail(request: CrashReportDetailBulkUpdateRequest): Observable<ApiOperationStatus & CrashReportDetailBulkUpdateResponse> {
    return this.apiGateway.post('oss/crashReport/bulkFormField', request, true, {}, '2.0.0');
  }

  addSchemaGroup(request: AddOrRemoveGroupRequest): Observable<CrashReportDetail> {
    return this.apiGateway.post('oss/crashReport/addToList', request, true, null, '2.0.0');
  }

  removeSchemaGroup(request: AddOrRemoveGroupRequest): Observable<CrashReportDetail> {
    return this.apiGateway.delete('oss/crashReport/removeFromList', request, true, null);
  }

  addSubGroup(request: AddOrRemoveGroupRequest): Observable<CrashReportDetail> {
    return this.apiGateway.post('oss/crashReport/addToList', request, true, null, '2.0.0');
  }

  removeSubGroup(request: AddOrRemoveGroupRequest): Observable<CrashReportDetail> {
    return this.apiGateway.delete('oss/crashReport/removeFromList', request, true, null);
  }

  addCrashReportDetail(request: AddCrashReportDetailRequest): Observable<ApiOperationStatus & { crash_report: CrashReportDetail }> {
    return this.apiGateway.post('oss/crashReport/newReport', request, true, null, '2.0.0');
  }

  swapVehicles(crashNumber: string, vehicleIndex1: number, vehicleIndex2: number): Observable<CrashReportDetail> {
    const body = { crash_num: crashNumber, group_type: 'vehicle', group_index_1: vehicleIndex1 + 1, group_index_2: vehicleIndex2 + 1 };
    return this.apiGateway.put('oss/crashReport/swapGroup', body, true, {}, '2.0.0');
  }

  deleteCrashReportDetail(crashNumber: string): Observable<ApiOperationStatus> {
    return this.apiGateway.delete('oss/dashboard/report', { crash_num: crashNumber }, true, null, '1.0.0');
  }

  private buildUrlSearchParams(
    serviceLocationId: number,
    limit: number,
    offset: number,
    statuses: string[],
    hydrateReviews: boolean,
    hydrateECrashSubmissionStatus: boolean,
    filter: CrashReportSummaryFilter
  ): URLSearchParams {
    const urlSearchParams = new URLSearchParams();

    urlSearchParams.append('service_location_id', serviceLocationId.toString());
    urlSearchParams.append('limit', limit.toString());
    urlSearchParams.append('offset', offset.toString());
    urlSearchParams.append('hydrate_reviews', hydrateReviews.toString());
    urlSearchParams.append('hydrate_ecrash_submission_status', hydrateECrashSubmissionStatus.toString());

    statuses.forEach(status => urlSearchParams.append('status', status));

    [
      { key: 'item_number', value: filter?.itemNumber },
      { key: 'agent', value: filter?.agent },
      { key: 'start_date', value: filter?.startDate },
      { key: 'end_date', value: filter?.endDate },
      { key: 'start_time', value: filter?.startTime },
      { key: 'end_time', value: filter?.endTime },
      { key: 'location', value: filter?.location },
    ]
      .filter(({ value }) => CommonUtils.isDefinedAndNonEmptyString(value))
      .forEach(({ key, value }) => urlSearchParams.append(key, value));

    return urlSearchParams;
  }
}
