import { Injectable } from '@angular/core';
import { Observable, of, tap } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { ApiCurrentUser } from '../../../data-model/models/api-current-user.model';
import { SignUpRequest } from '../../../data-model/models/sign-up-request.model';

@Injectable({ providedIn: 'root' })
export class AuthService {
  constructor(private readonly apiGatewayService: ApiGatewayService) {}

  getCurrentVersion(): Observable<string> {
    return of(Math.random() < 0.5 ? '2.0.9' : '2.0.10').pipe(tap(version => console.log('version:', version)));
  }

  checkToken(): Observable<ApiOperationStatus> {
    return this.apiGatewayService.get('common/accounts/checkToken');
  }

  signIn(username: string, password: string): Observable<ApiCurrentUser> {
    return this.apiGatewayService.post('oss/accounts/signin', { username, password }, false);
  }

  signOut(): Observable<ApiOperationStatus> {
    return this.apiGatewayService.post('common/accounts/signout', {}, false);
  }

  forgotPassword(username: string, email: string): Observable<ApiOperationStatus & { uExists?: boolean }> {
    return this.apiGatewayService.post('oss/accounts/forgotPassword', { username, email }, false);
  }

  resetPassword(password: string, token: string): Observable<ApiOperationStatus & { uExists?: boolean }> {
    return this.apiGatewayService.post('common/accounts/resetPassword', { password, token }, false);
  }

  signUp(request: SignUpRequest): Observable<ApiCurrentUser> {
    return this.apiGatewayService.post('common/accounts/signup', request, false);
  }
}
