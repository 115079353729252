import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { NetworkStatusService } from '../../../shared/services/network-status/network-status.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { RuleSetService } from '../../services/api/rule-set/rule-set.service';
import { RuleSetActions } from '../actions';
import { ServiceLocationSelectors } from '../selectors';

@Injectable()
export class RuleSetEffects {
  loadDefaultRuleSet$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RuleSetActions.loadDefaultRuleSet),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocation)),
      filter(() => this.networkStatusSerivce.isOnline),
      switchMap(([, serviceLocation]) =>
        this.ruleSetService.getRuleSet(null, serviceLocation.id).pipe(
          map(ruleSetResponse => RuleSetActions.defaultRuleSetLoaded(ruleSetResponse)),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly ruleSetService: RuleSetService,
    private readonly serverErrorService: ServerErrorService,
    private readonly networkStatusSerivce: NetworkStatusService
  ) {}
}
