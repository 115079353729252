/* eslint-disable @typescript-eslint/no-explicit-any */
import { CommonModule } from '@angular/common';
import { APP_INITIALIZER, Component, ErrorHandler, OnInit } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { Router, RouterOutlet } from '@angular/router';
import { Store } from '@ngrx/store';
import * as Sentry from '@sentry/angular-ivy';
import Radar from 'radar-sdk-js';
import { Observable, first } from 'rxjs';
import packageInfo from '../../package.json';
import { environment } from '../environments/environment';
import { CrashReportActions } from './modules/crash-report/store/actions';
import { CrashReportSelectors } from './modules/crash-report/store/selectors';
import { ServiceWorkerCrashReportData } from './modules/shared/data-model/models/service-worker-crash-report-data.model';
import { TechnicalException } from './modules/shared/data-model/models/technical-exception.model';
import { ServiceWorkerMessageType } from './modules/shared/data-model/types/service-worker-message-type.type';
import { SnackbarService } from './modules/shared/modules/snackbar/services/snackbar/snackbar.service';
import { SentryErrorHandler } from './modules/shared/sentry-error-handler';
import { ConfigService } from './modules/shared/services/config/config.service';
import { NetworkStatusService } from './modules/shared/services/network-status/network-status.service';
import { SmartyStreetsService } from './modules/shared/services/smarty-streets/smarty-streets.service';
import { LoadingActions } from './modules/shared/store/actions';
import { LoadingSelectors } from './modules/shared/store/selectors';
import { CommonUtils } from './modules/shared/utils/common-utils';
import { OssOptional } from './modules/shared/utils/oss-optional';
import { AppState } from './store/state/app.state';

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [RouterOutlet, MatProgressSpinnerModule, CommonModule],
  templateUrl: './app.component.html',
  providers: [
    { provide: ErrorHandler, useClass: SentryErrorHandler },
    { provide: Sentry.TraceService, deps: [Router] },
    { provide: APP_INITIALIZER, useFactory: () => () => {}, deps: [Sentry.TraceService], multi: true },
  ],
  styleUrl: './app.component.scss',
})
export class AppComponent implements OnInit {
  isLoading$: Observable<boolean>;
  loadingMessage$: Observable<string | null>;

  // inject the network status service to ensure it's included in the bundle
  // it informs the service worker when the network status changes
  constructor(
    private readonly networkStatusService: NetworkStatusService,
    private readonly store: Store<AppState>,
    private readonly router: Router,
    private readonly snackbarService: SnackbarService,
    private readonly configService: ConfigService,
    private readonly smartyStreetsService: SmartyStreetsService
  ) {
    // i was unregistering the service worker, installing, activating, and forcing a reload
    // this ensured the current service worker was in control of the page
    // this worked locally, however, it caused an infinite loop in the deployed version
    // this.unregisterServiceWorkers();

    OssOptional.ofNullable(navigator.serviceWorker).ifPresent(serviceWorker =>
      serviceWorker
        .register('./custom-sw.js')
        .then(registration => {
          if (registration.installing) {
            this.trackInstalling(registration.installing);
            return;
          }
          if (registration.waiting) {
            return;
          }
          registration.addEventListener('updatefound', () => this.trackInstalling(registration.installing));
        })
        .catch(error => console.error('Custom Service Worker registration failed', error))
    );
  }

  ngOnInit() {
    this.adjustFontSizeOnEnvironment();

    this.configureSentry();
    this.configureAddressAutocompleteService();

    this.isLoading$ = this.store.select(LoadingSelectors.selectShowLoadingIndicator);
    this.loadingMessage$ = this.store.select(LoadingSelectors.selectLoadingMessage);

    // if the there is no service worker, show user a warning message
    // could also use an alert/confirm dialog or a modal and call window.location.reload() to refresh the page
    if (CommonUtils.isNullOrUndefined(navigator.serviceWorker)) {
      this.snackbarService.showSnackbar('Offline mode is not supported.', 'info');
      return;
    }

    // if there is a service worker but it's not controlling the page, prompt the user to refresh
    if (CommonUtils.isNullOrUndefined(navigator.serviceWorker.controller)) {
      this.snackbarService.showSnackbar('Please refresh the page to update the app. ', 'info');
      return;
    }

    navigator.serviceWorker.addEventListener('message', event => {
      const messageType: ServiceWorkerMessageType = event.data.action;
      const crashReportDataList: ServiceWorkerCrashReportData[] = event.data.data;

      switch (messageType) {
        case 'processingStart': {
          this.store.dispatch(LoadingActions.showLoadingIndicator({ message: 'Processing offline requests...' }));
          break;
        }

        case 'processingComplete': {
          this.store
            .select(CrashReportSelectors.selectCrashReportSummary)
            .pipe(first())
            .subscribe(crashReportSumary => {
              const crashReportDataScopedToSelectedCrashReportSummary = crashReportDataList.find(
                crashReport =>
                  crashReport.crash_num ===
                  OssOptional.ofNullable(crashReportSumary)
                    .map(crashReportSummary => crashReportSummary.oss_id)
                    .orElseNull()
              );
              if (CommonUtils.isNullOrUndefined(crashReportDataScopedToSelectedCrashReportSummary)) {
                this.store.dispatch(LoadingActions.hideLoadingIndicator());
                return;
              }

              const { isNewCrashReport, additionWasSuccessful, foundCrashNumber, actualCrashReportNumber, localReportNumber } =
                crashReportDataScopedToSelectedCrashReportSummary;
              if (isNewCrashReport && additionWasSuccessful && foundCrashNumber && actualCrashReportNumber !== crashReportSumary.oss_id) {
                const updatedCrashReportSummary = { ...crashReportSumary, oss_id: actualCrashReportNumber };
                this.store.dispatch(CrashReportActions.setCrashReportSummary({ crashReportSummary: updatedCrashReportSummary }));
              }
              if (isNewCrashReport && (!additionWasSuccessful || !foundCrashNumber)) {
                this.snackbarService.showSnackbar(`Failed to add crash report ${localReportNumber}`, 'error');
                this.router.navigate(['/crash-report']);
              }

              this.store.dispatch(LoadingActions.hideLoadingIndicator());
            });
        }
      }
    });
  }

  private unregisterServiceWorkers(): void {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.getRegistrations().then(registrations => {
        for (const registration of registrations) {
          registration.unregister();
        }
      });
    }
  }

  private trackInstalling(worker: ServiceWorker): void {
    worker.addEventListener('statechange', () => {
      if (worker.state === 'installed') {
        // window.location.reload();
      }
    });
  }

  private configureAddressAutocompleteService(): void {
    switch (true) {
      case !this.configService.getSmartyStreetsActive() && !this.configService.getRadarActive(): {
        break;
      }

      case this.configService.getSmartyStreetsActive() && !this.configService.getRadarActive(): {
        this.configureSmartyStreets();
        break;
      }

      case !this.configService.getSmartyStreetsActive() && this.configService.getRadarActive(): {
        this.configureRadar();
        break;
      }

      default: {
        throw new TechnicalException('Both SmartyStreets and Radar are active. Only one can be active at a time.');
      }
    }
  }

  private configureRadar(): void {
    Radar.initialize(this.configService.getRadarApiKey());
  }

  private configureSmartyStreets(): void {
    this.smartyStreetsService.configure();
  }

  private configureSentry(): void {
    if (!environment.production) {
      return;
    }

    // Set profilesSampleRate to 1.0 to profile every transaction.
    // Since profilesSampleRate is relative to tracesSampleRate,
    // the final profiling rate can be computed as tracesSampleRate * profilesSampleRate
    // For example, a tracesSampleRate of 0.5 and profilesSampleRate of 0.5 would
    // result in 25% of transactions being profiled (0.5 * 0.5 = 0.25)
    const profilesSampleRate = 1.0;

    Sentry.init({
      dsn: 'https://4d6fb42a1f1ef2867f1d94f840e32d2b@o4506400343851008.ingest.sentry.io/4506418430410752',
      release: `accident-reporting-app@${packageInfo.version}`,
      environment: environment.name.toLowerCase(),
      integrations: [
        new Sentry.BrowserTracing({ tracePropagationTargets: [environment.appConfig.apiUrl] }),
        Sentry.browserTracingIntegration(),
        Sentry.metrics.metricsAggregatorIntegration(),
        Sentry.browserTracingIntegration({ enableInp: true }),
        new Sentry.BrowserProfilingIntegration(),
        new Sentry.Replay(),
        new Sentry.Feedback({
          autoInject: false,
          showBranding: false,
          showEmail: false,
          isNameRequired: true,
          buttonLabel: 'Share Feedback',
          submitButtonLabel: 'Send Feedback',
          formTitle: 'Share Feedback',
          messagePlaceholder: 'Please share your feedback.',
          successMessageText: 'Thank you for your feedback!',
        }),
      ],
      autoSessionTracking: true,
      routingInstrumentation: Sentry.routingInstrumentation,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
      tracesSampleRate: 0.1,
      tracePropagationTargets: ['localhost', /^https:\/\/api\.onsceneservices\.com/, /^https:\/\/dev-api\.onsceneservices\.com/],
      profilesSampleRate,
    } as any);
  }

  private adjustFontSizeOnEnvironment(): void {
    if (!environment.production) {
      const style = document.createElement('style');
      style.innerHTML = 'html, body { height: 100%; font-size: 24px; }';
      // document.head.appendChild(style);
    }
  }
}
