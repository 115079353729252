import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, filter, map, switchMap } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { NetworkStatusService } from '../../../shared/services/network-status/network-status.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { SchemaService } from '../../services/api/schema/schema.service';
import { SchemaActions } from '../actions';
import { ServiceLocationSelectors } from '../selectors';

@Injectable()
export class SchemaEffects {
  loadDefaultSchema$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SchemaActions.loadDefaultSchema),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocation)),
      filter(() => this.networkStatusSerivce.isOnline),
      switchMap(([, serviceLocation]) =>
        this.schemaService.getSchema(null, serviceLocation.id).pipe(
          map(schemaResponse => SchemaActions.defaultSchemaLoaded(schemaResponse)),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly schemaService: SchemaService,
    private readonly serverErrorService: ServerErrorService,
    private readonly networkStatusSerivce: NetworkStatusService
  ) {}
}
