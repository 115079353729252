import { Injectable } from '@angular/core';
import { Actions, concatLatestFrom, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, map, switchMap } from 'rxjs';
import { AppState } from '../../../../store/state/app.state';
import { LocalStorageService } from '../../../shared/services/local-storage/local-storage.service';
import { ServerErrorService } from '../../../shared/services/server-error/server-error.service';
import { UnitService } from '../../services/api/unit/unit.service';
import { UnitActions } from '../actions';
import { ServiceLocationSelectors } from '../selectors';

@Injectable()
export class UnitEffects {
  loadUnits$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UnitActions.loadUnits),
      concatLatestFrom(() => this.store.select(ServiceLocationSelectors.selectServiceLocationId)),
      switchMap(([, serviceLocationId]) =>
        this.unitService.getUnits(serviceLocationId).pipe(
          map(units => UnitActions.unitsLoaded({ units })),
          catchError(error => this.serverErrorService.handleError(error))
        )
      )
    );
  });

  assignUnit$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(UnitActions.assignUnit),
      switchMap(action => {
        return this.unitService.assignUnit(action.unit.id).pipe(
          map(response => {
            this.localStorageService.setUnit(action.unit);
            this.localStorageService.setAssignmentId(response.id);
            return UnitActions.unitAssigned({ unit: action.unit, assignmentId: response.id });
          }),
          catchError(error => this.serverErrorService.handleError(error))
        );
      })
    );
  });

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly unitService: UnitService,
    private readonly serverErrorService: ServerErrorService,
    private readonly localStorageService: LocalStorageService
  ) {}
}
