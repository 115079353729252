import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiOperationStatus } from '../../../../shared/data-model/models/api-operation-status.model';
import { ApiGatewayService } from '../../../../shared/services/api-gateway/api-gateway.service';
import { CrashReportDetailStatus } from '../../../data-model/types/crash-report-detail-status.type';

@Injectable({ providedIn: 'root' })
export class CrashReportStatusService {
  private readonly CRASH_NUMBER = 'Crash_Number';

  constructor(private readonly apiGateway: ApiGatewayService) {}

  updateCrashReportDetailStatus(crashReportNumber: string, status: CrashReportDetailStatus): Observable<ApiOperationStatus> {
    return this.apiGateway.post(
      'oss/dashboard/reportStatus',
      { crash_num: crashReportNumber, status },
      true,
      this.getHeadersOnCrashNumber(crashReportNumber),
      '2.0.0'
    );
  }

  private getHeadersOnCrashNumber(crashNumber: string): { [key: string]: string } {
    return { [this.CRASH_NUMBER]: crashNumber };
  }
}
